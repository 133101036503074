import * as React from "react";
import { Box } from "@mui/system";
import { useTheme } from "@mui/styles";

export default function Sidebar({ children }) {
  const theme = useTheme();
  const classes = styles(theme);
  return <Box sx={classes.sidebar}>{children}</Box>;
}

function styles(theme) {
  return {
    sidebar: {
      flex: "0 0 300px",
      backgroundColor: "#fff",
      position: "relative",
      ...theme.scrollbar,
    },
  };
}
