import * as React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import Button from "./ui/button";
import AddIcon from "@mui/icons-material/Add";

export default function Actions({ children, message, route, action, width }) {
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <Box sx={classes.actionBox}>
      <Button
        sx={classes.actionButton}
        color="primary"
        style={{ width }}
        onClick={action}
        variant="contained"
      >
        <AddIcon sx={classes.actionIcon} />
        {message}
      </Button>
      {children}
    </Box>
  );
}

function styles(theme) {
  return {
    actionBox: {
      position: "sticky",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      padding: "15px",
      backgroundColor: "#fff",
      borderRadius: ` 0 0 ${theme.radiuses.small} ${theme.radiuses.small}`,
    },
    actionButton: {
      justifyContent: "space-between",
    },
    actionIcon: {
      fontSize: "20px",
      marginRight: "10px",
    },
  };
}
