import * as React from "react";
import Actions from "../../list-actions";
import EditModal from "../article-categories/edit-modal";
import { useAdminContext } from "../../../hooks/use-admin-context";

const initialCategory = { typeId: 1, title: "" };

export default function ListActions({
  addCategory,
  callback,
  categories,
  types,
  kinds,
}) {
  const { openModal } = useAdminContext();
  const createCategory = async (newCategory) => {
    const category = { ...newCategory, position: categories.length } || {
      ...initialCategory,
      position: categories.length,
    };
    const method = newCategory ? addCategory : callback;
    await method(category);
  };

  const onAddClick = () =>
    openModal(
      <EditModal
        item={initialCategory}
        callback={createCategory}
        types={types}
        kinds={kinds}
        title="Ajouter une catégorie"
      />
    );

  return <Actions message="Ajouter une catégorie" action={onAddClick} />;
}
