import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "../../ui/button";
import * as FileManager from "../../../services/file-manager";

export default function PictureInsertion({
  title,
  src,
  fileChanged,
  width,
  height,
  dimensionned,
  buttonMode,
}) {
  const theme = useTheme();
  const classes = styles(theme);
  const onClick = () => FileManager.load({ fileChanged, width, height });
  const style = dimensionned ? { width, height } : {};

  return (
    <Box sx={classes.pictureInsertion}>
      <Typography sx={classes.pictureInsertionTitle}>{title}</Typography>
      <Input
        src={src}
        onClick={onClick}
        style={style}
        buttonMode={buttonMode}
      />
    </Box>
  );
}

function Input({ src, onClick, style, buttonMode }) {
  const theme = useTheme();
  const classes = styles(theme);
  if (buttonMode) {
    const label = src ? "Modifier" : "Ajouter";
    return (
      <Button onClick={onClick} color="primary" variant="contained">
        {label}
      </Button>
    );
  }
  const props = { onClick, style };
  return src ? (
    <Box
      component="img"
      alt=""
      src={src}
      sx={classes.pictureInsersionInput}
      {...props}
    />
  ) : (
    <Box sx={classes.pictureInsersionInput} {...props}>
      <Typography sx={classes.pictureInsersionPlaceholder}>
        Insérer une image
      </Typography>
      <CloudUploadIcon sx={classes.pictureInsersionPlaceholderIcon} />
    </Box>
  );
}

function styles(theme) {
  return {
    pictureInsertion: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: 1,
    },
    pictureInsertionTitle: {
      marginBottom: "10px",
      color: theme.palette.grey[600],
    },
    pictureInsersionPlaceholder: {},
    pictureInsersionPlaceholderIcon: {
      height: "48px",
      width: "48px",
    },
  };
}
