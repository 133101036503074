import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";
import { getFlatenedNavigation } from "../navigation";

export default function Content({ feelingTypes }) {
  const { user } = useAuth();
  const navigation = getFlatenedNavigation(user.superadmin, feelingTypes);
  return (
    <Routes>
      {navigation.map(({ slug, Module, Icon, options }, i) => {
        return (
          <Route
            key={i}
            path={`/${slug}`}
            element={<Module Icon={Icon} {...options} />}
          />
        );
      })}
    </Routes>
  );
}
