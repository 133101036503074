import * as ExternalScript from "./external-script";

export async function load(params) {
  const { url, token } = window.CmsConfig.filemanager;
  if (!url || !token) return;
  await ExternalScript.load(`${url}/static/js/file-manager.js`, "file-picker");
  window.openFileManager({
    fileManagerAPIURL: url,
    token: token,
    uploadOnly: false,
    ...params,
  });
}
