import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import App from "./app";
import muiTheme from "./theme";
import { AuthProvider } from "./hooks/use-auth";
import { AdminContextProvider } from "./hooks/use-admin-context";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <AuthProvider>
        <AdminContextProvider>
          <App />
        </AdminContextProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
