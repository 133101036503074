export function slugify(str) {
  let newStr = str.replace(/^\s+|\s+$/g, "");

  newStr = newStr.toLowerCase();

  const from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  const to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    newStr = newStr.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  newStr = newStr
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  const lastChar = newStr[newStr.length - 1];

  newStr = lastChar === "-" ? newStr.slice(0, -1) : newStr;

  return newStr;
}

export function isObject(variable) {
  return Object.prototype.toString.call(variable) === "[object Object]";
}

export function formatUsername(email) {
  const [leftPart] = email.split("@");
  const parts = leftPart.split(".");
  if (parts.length === 2) {
    return parts.map(capitalize).join(" ");
  }
  return email;
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
