import { Box } from "@mui/system";
import * as React from "react";
import * as API from "../../services/api";
import ArticleList from "./lists/article";

const messages = {
  title: "Gestion de l'annuaire",
  addButton: "Ajouter une organisation",
  deleteModal: "Supprimer une organisation",
  deleteSuccess: "Organisation supprimée",
};

const additionalColumns = [
  { field: "logo", headerName: "Logo", renderCell: renderLogoCell, width: 100 },
];

const columnsOrder = ["id", "logo", "title"];

export default function Annual({ Icon }) {
  return (
    <ArticleList
      Icon={Icon}
      get={get}
      remove={remove}
      route="/annuaire"
      messages={messages}
      additionalColumns={additionalColumns}
      columnsOrder={columnsOrder}
    />
  );
}

function renderLogoCell(row) {
  const classes = styles();
  const { title, logo } = row;

  return (
    <Box sx={classes.logo}>
      {logo ? <Box component="img" src={logo} alt={title} /> : <div />}
    </Box>
  );
}

function styles() {
  return {
    logo: {
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      boxShadow: "var(--shadow-300)",
      overflow: "hidden",
      "& > img, & > div": {
        width: "100%",
        height: "100%",
      },
    },
  };
}

function get(token) {
  return API.getOrganisations(token, 1);
}

function remove(token, id) {
  return API.removeOrganisation(token, id, 1);
}
