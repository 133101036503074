import * as React from "react";
import { useNavigate } from "react-router-dom";
import ListActions from "../list-actions/article-list";
import List from "../../list";
import Card from "../../card";
import RemoveModal from "../common/remove-modal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../../../hooks/use-auth";
import { useAdminContext } from "../../../hooks/use-admin-context";

export default function ArticleList({
  Icon,
  get,
  remove,
  route,
  messages,
  additionalColumns = [],
  columnsOrder,
}) {
  const [rows, setRows] = React.useState([]);
  const [ready, setReady] = React.useState(false);
  const { token } = useAuth();
  const { openModal, setLoader } = useAdminContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetcher = async () => {
      const result = await get(token);
      setRows(result);
      setReady(true);
    };
    fetcher();
    return () => setRows(undefined);
  }, [get, token]);

  const removeArticle = (id) => async () => {
    setLoader(true);
    const rows = await remove(token, id);
    if (rows) {
      setRows(rows);
      setLoader(false);
      return true;
    }
    setLoader(false);
  };

  const onEditButtonClick = (id) => navigate(`${route}/${id}`);
  const onRemoveButtonClick = (id) => {
    openModal(<RemoveModal remove={removeArticle(id)} messages={messages} />);
  };

  const columns = [
    { field: "id", hidden: true, headerName: "id" },
    { field: "title", headerName: "Titre" },
    ...additionalColumns,
  ];

  const rowActions = [
    { Icon: EditIcon, callback: onEditButtonClick },
    { Icon: DeleteIcon, callback: onRemoveButtonClick },
  ];

  const actionsProps = { messages, route };

  return (
    <Card
      Icon={Icon}
      title={messages.title}
      ListActions={ListActions}
      actionsProps={actionsProps}
    >
      <List
        loading={rows === undefined}
        columns={columns}
        rows={rows}
        rowActions={rowActions}
        columnsOrder={columnsOrder}
        ready={ready}
      />
    </Card>
  );
}
