import * as React from "react";

export default function Bookmark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M7 4a1 1 0 0 0-1 1v14.06l5.42-3.87a1 1 0 0 1 1.16 0L18 19.06V5a1 1 0 0 0-1-1ZM4.88 2.88A3 3 0 0 1 7 2h10a3 3 0 0 1 3 3v16a1 1 0 0 1-1.58.81L12 17.23l-6.42 4.58A1 1 0 0 1 4 21V5a3 3 0 0 1 .88-2.12Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
