import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";
import UbloZone from "../ublo-zone";
import Card from "../card";
import Sidebar from "../sidebar";
import Actions from "../page-actions";
import CancelModal from "./common/cancel-modal";
import { useTheme } from "@mui/styles";
import { useAdminContext } from "../../hooks/use-admin-context";
import { useAuth } from "../../hooks/use-auth";
import * as API from "../../services/api";

const initialTip = { title: "", content: "" };

export default function Tip() {
  const [tip, setTip] = React.useState();
  const { id } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = styles(theme);
  const { openModal, ublo, activateUblo, desactivateUblo } = useAdminContext();
  const { triggerError, triggerSuccess } = useAdminContext();

  React.useEffect(() => {
    const get = async () => {
      await desactivateUblo();
      if (id === "creation") {
        setTip(initialTip);
      } else {
        const result = await API.getTip(token, id);
        setTip(result);
      }
      await activateUblo();
    };
    get();
  }, [activateUblo, desactivateUblo, id, token, ublo]);

  const onTitleChange = (tip) => (e) => {
    const { value } = e.target;
    setTip({ ...tip, title: value });
  };

  const cancel = () => openModal(<CancelModal page="/astuces" />);
  const save = async () => {
    await desactivateUblo();
    const { title } = tip;
    if (!title) return triggerError(`Veuillez indiquer un titre`);
    const content = document.querySelector("section").outerHTML;
    const { id: newId } =
      id === "creation"
        ? await API.createTip(token, title, content)
        : await API.updateTip(token, id, title, content);
    if (id === "creation" && newId) navigate(`/astuce/${newId}`);
    triggerSuccess(`Astuce ${title} ajoutée`);
    await activateUblo();
  };

  const actionsProps = { save, cancel };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.inner}>
        <Card ListActions={Actions} actionsProps={actionsProps}>
          <Box sx={classes.card}>
            {tip && (
              <UbloZone
                sections="astuce"
                maxSections="1"
                defaultContent="astuce"
                content={tip.content}
              />
            )}
          </Box>
        </Card>
      </Box>
      <Sidebar>
        <Box sx={classes.box}>
          <TextField
            sx={classes.title}
            label="Titre"
            value={tip?.title || ""}
            onChange={onTitleChange(tip)}
            variant="standard"
          />
        </Box>
      </Sidebar>
    </Box>
  );
}

function styles(theme) {
  return {
    root: {
      display: "flex",
    },
    inner: {
      flex: "1 1 100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: "#fafafa",
      "& > div": {
        padding: "25px 25px 65px 25px",
      },
      ...theme.scrollbar,
    },
    box: {
      padding: "15px",
    },
    title: {
      width: "100%",
    },
    card: {
      position: "relative",
      width: "345px",
      padding: "25px",
      boxSizing: "content-box",
    },
  };
}
