import * as React from "react";
import { Card as MUICard, CardActions, CardHeader } from "@mui/material";
import { useTheme } from "@mui/styles";
import CustomButton from "./ui/button";
import * as Utils from "../services/utils";
import { Box } from "@mui/system";

export default function Card({
  Icon,
  title,
  actions,
  ListActions,
  actionsProps,
  isSaveButtonDisabled,
  innerStyles = {},
  children,
}) {
  const theme = useTheme();
  const classes = styles(theme);

  const renderActions = () => (
    <CardActions sx={classes.cardActions}>
      {actions.map(({ title, callback, ...props }) => (
        <Button
          key={`button-${Utils.slugify(title)}`}
          sx={classes.cardAction}
          onClick={callback}
          {...props}
        >
          {title}
        </Button>
      ))}
    </CardActions>
  );

  const Title = (
    <Box>
      {Icon && <Icon />}
      {title}
    </Box>
  );

  return (
    <Box sx={classes.card}>
      <MUICard sx={{ ...classes.cardInner, ...innerStyles }}>
        {title && <CardHeader sx={classes.cardTitle} title={Title} />}
        {children}
        {actions && renderActions}
        {ListActions && (
          <ListActions
            isSaveButtonDisabled={isSaveButtonDisabled}
            {...actionsProps}
          />
        )}
      </MUICard>
    </Box>
  );
}

function styles(theme) {
  return {
    card: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingTop: "25px",
    },
    cardInner: {
      position: "relative",
      width: "90%",
      maxWidth: "90%",
      display: "flex",
      flexDirection: "column",
      margin: "0 auto",
      overflow: "visible",
      boxShadow: "none",
      borderRadius: theme.radiuses.small,
      "&::after": {
        content: "''",
        position: "absolute",
        top: "100%",
        left: "0",
        width: "100%",
        height: "25px",
        pointerEvents: "none",
        touchActions: "none",
      },
    },
    cardTitle: {
      padding: "16px 16px 0 16px",
      textAlign: "left",
      "& span": {
        fontFamily: "var(--title-font)",
      },
      "& span :first-of-type": {
        display: "flex",
        alignItems: "center",
      },
      "& svg": {
        width: "24px",
        height: "24px",
        marginRight: "8px",
        fill: "var(--secondary)",
      },
    },
    cardActions: {
      padding: "0 15px",
    },
    cardAction: {
      marginLeft: "auto",
    },
  };
}

function Button({ ...props }) {
  return <CustomButton color="primary" variant="contained" {...props} />;
}
