import * as React from "react";
import { Box } from "@mui/system";

function UbloZone(
  { content = "", defaultContent, sections, maxSections, onReady, tooltip },
  ref
) {
  const classes = styles();

  React.useEffect(() => {
    const init = async () => {
      if (window.Cms) {
        onReady?.();
      }
    };
    init();
  }, [onReady]);

  const attributes = {
    "data-cms-fixed-section": sections,
    "data-cms-max-sections": maxSections,
    "data-cms-tooltip": tooltip,
    "data-cms-default-content": defaultContent,
  };

  return (
    <Box sx={classes.editor}>
      <Box
        ref={ref}
        className="cms"
        {...attributes}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Box>
  );
}

function styles() {
  return {
    editor: {
      padding: "0",
    },
  };
}

export default React.forwardRef(UbloZone);
