import * as React from "react";
import { IconButton } from "@mui/material";
import Card from "../card";
import List from "../list";
import { useAuth } from "../../hooks/use-auth";
import { useAdminContext } from "../../hooks/use-admin-context";
import * as API from "../../services/api";
import RemoveModal from "./common/remove-modal";
import EditModal from "./article-categories/edit-modal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import ListActions from "./list-actions/article-categories";

const messages = {
  deleteModal: "Supprimer une catégories",
  deleteConfirm: "Etes-vous sur de vouloir supprimer cette catégorie ?",
  deleteSuccess: "Catégorie supprimée",
};

export default function ArticleCategories({ Icon }) {
  const [categories, setCategories] = React.useState();
  const [ready, setReady] = React.useState(false);
  const [types, setTypes] = React.useState();
  const [kinds, setKinds] = React.useState();
  const classes = styles();
  const { token } = useAuth();
  const { setLoader, triggerError, triggerSuccess, openModal } =
    useAdminContext();

  React.useEffect(() => {
    const get = async () => {
      const [categoryList, kinds, typeList] = await Promise.all([
        API.getArticleCategoryList(token),
        API.getArticleCategoryKindList(token),
        API.getArticleCategoryTypeList(token),
      ]);
      setTypes(typeList);
      setKinds(kinds);
      const categoriesWithTypes = categoryList.map((category) => {
        const type = typeList.find((type) => type.id === category.typeId);
        return { ...category, type: type.title };
      });
      setCategories(categoriesWithTypes);
      setReady(true);
    };
    get();
    return () => setCategories(undefined);
  }, [token]);

  const addCategory = async (add) => {
    setLoader(true);
    const { typeId: type, kind, title, position } = add;
    if (!title) {
      setLoader(false);
      return triggerError("Veuillez nommer votre entrée.");
    }
    if (type === 2 && !kind) {
      setLoader(false);
      return triggerError("Veuillez choisir une sous catégorie.");
    }
    const newCategories = await API.createCategory(
      token,
      type,
      kind,
      title,
      position
    );
    const categoriesWithTypes = newCategories.map((category) => {
      const type = types.find((type) => type.id === category.typeId);

      return { ...category, type: type.title };
    });
    setCategories(categoriesWithTypes);
    triggerSuccess("Nouvelle citation ajoutée.");
    setLoader(false);
  };

  const update = async ({ id, typeId, kind, title, position }) => {
    setLoader(true);
    const newCategories = await API.updateCategory(
      token,
      id,
      typeId,
      kind,
      title,
      position
    );
    const categoriesWithTypes = newCategories.map((category) => {
      const type = types.find((type) => type.id === category.typeId);
      return { ...category, type: type.title };
    });
    setCategories(categoriesWithTypes);
    setLoader(false);
  };

  const remove = (id) => async () => {
    const { status } = await API.removeCategory(token, id);
    if (status === 200) {
      const newCategories = categories
        .filter((category) => category.id !== id)
        .map((category, i) => ({ ...category, position: i }));
      setCategories(newCategories);
      return true;
    }
  };

  const changeOrder = async (row, rank = 0) => {
    setLoader(true);
    const { position, typeId } = row;
    const shift = position + rank;
    if (shift < 0 || shift > categories.length - 1) return setLoader(false);
    const swapper = categories.find((category) => category.position === shift);
    await Promise.all([
      update({ ...row, typeId, position: shift }),
      update({ ...swapper, typeId: swapper.typeId, position }),
    ]);
  };

  const lowerOrder = (row) => changeOrder(row, -1);
  const upperOrder = (row) => changeOrder(row, 1);

  const renderOrderTools = (row) => {
    const { id, position } = row;
    return (
      <>
        <IconButton
          key={`order-up-${id}`}
          disabled={position >= categories.length - 1}
          onClick={() => upperOrder(row)}
        >
          <ExpandMoreIcon />
        </IconButton>
        <IconButton
          key={`order-down-${id}`}
          disabled={position === 0}
          onClick={() => lowerOrder(row)}
        >
          <ExpandLessIcon />
        </IconButton>
      </>
    );
  };

  const columns = [
    { field: "id", hidden: true, headerName: "id" },
    { field: "typeId", hidden: true, headerName: "Type id" },
    {
      field: "position",
      headerName: "Ordre",
      width: 130,
      renderCell: renderOrderTools,
    },
    { field: "title", headerName: "Titre" },
    { field: "type", headerName: "Type" },
    { field: "kindLabel", headerName: "Sous-catégorie" },
  ];

  const onWriteButtonClick = (id) => {
    const [category] = categories.filter((category) => category.id === id);
    openModal(
      <EditModal
        item={category}
        callback={update}
        types={types}
        kinds={kinds}
        title="Éditer une catégorie"
      />
    );
  };
  const onRemoveButtonClick = (id) =>
    openModal(<RemoveModal remove={remove(id)} messages={messages} />);

  const actions = [
    { Icon: EditIcon, callback: onWriteButtonClick },
    { Icon: DeleteIcon, callback: onRemoveButtonClick },
  ];
  const actionsProps = { categories, addCategory, types, kinds };

  return (
    <Box sx={classes.root}>
      <Card
        Icon={Icon}
        title="Gestion des catégories"
        ListActions={ListActions}
        actionsProps={actionsProps}
      >
        <List
          loading={categories === undefined}
          columns={columns}
          rows={categories}
          rowActions={actions}
          ready={ready}
        />
      </Card>
    </Box>
  );
}

function styles() {
  return {
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
}
