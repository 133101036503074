export function load(url, id) {
  const script = document.getElementById(id);
  if (script) return Promise.resolve();
  return new Promise((resolve) => {
    const el = document.createElement("script");
    el.id = id;
    el.src = url;
    el.onload = resolve;
    document.body.appendChild(el);
  });
}
