import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Slider,
  Tooltip,
  InputAdornment,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import UbloZone from "../ublo-zone";
import Card from "../card";
import { useAdminContext } from "../../hooks/use-admin-context";
import { useAuth } from "../../hooks/use-auth";
import * as API from "../../services/api";
import * as Ublo from "../../services/ublo";
import Sidebar from "../sidebar";
import CancelModal from "./common/cancel-modal";
import PictureInsertion from "./article/picture-insertion";
import Actions from "../page-actions";
// import Carousels, { serializeCarousels } from './article/carousels'
import ArticleCoverPreview from "./article/article-cover-preview";
import ColorPicker from "../color-picker";
import Tags from "./article/tags";
import * as Utils from "../../services/utils";
import { HelpOutline } from "@mui/icons-material";

export default function Article({ type }) {
  const isZenArticle = type === 2;

  const coverWidth = isZenArticle ? 345 : 167;
  const coverHeight = isZenArticle ? 200 : 167;

  const contentRef = React.useRef(null);
  // const carouselsRef = React.useRef(null)
  const [article, setArticle] = React.useState();
  // const [carousels, setCarousels] = React.useState([])
  const [categories, setCategories] = React.useState([]);
  const [tags, setTags] = React.useState({});
  const [labels, setLabels] = React.useState([]);
  const [durations, setDurations] = React.useState([]);
  const [tagOpen, setTagOpen] = React.useState();
  const { id } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = styles(theme);
  const { openModal, activateUblo, desactivateUblo } = useAdminContext();
  const { triggerError, triggerSuccess } = useAdminContext();

  React.useEffect(() => {
    const get = async () => {
      await desactivateUblo();
      const [categories, tags, labels] = await Promise.all([
        API.getCategories(token, type),
        API.getTags(token),
        API.getLabels(token),
      ]);
      if (id === "creation") {
        setArticle(initialArticle(isZenArticle));
      } else {
        const [article /*, carousels */] = await Promise.all([
          API.getArticle(token, id),
          // API.getCarousels(token, id)
        ]);
        setArticle(article);
        // setCarousels(carousels)
      }
      if (isZenArticle) {
        const durations = await API.getDurations(token);
        setDurations(durations);
      }
      setCategories(categories);
      setTags(tags);
      setLabels(labels);
      await activateUblo();
    };
    get();
  }, [activateUblo, desactivateUblo, id, isZenArticle, token, type]);

  const pictureChanged =
    (picture) =>
    ({ path }) =>
      setArticle({ ...article, [picture]: `${Ublo.getConfig().cdn}${path}` });
  const coverChanged = pictureChanged("cover");

  const onTitleChange = (article) => (e) => {
    const { value } = e.target;
    setArticle({ ...article, title: value });
  };
  const onDescriptionChange = (article) => (e) => {
    const { value } = e.target;
    setArticle({ ...article, description: value });
  };

  const onAfterCancerChange = (article) => () => {
    setArticle({ ...article, afterCancer: !article.afterCancer });
  };

  const onLabelsChange = (article) => (e, newValue) => {
    setArticle({
      ...article,
      labels: newValue,
    });
  };

  const cancel = () => {
    openModal(
      <CancelModal
        page={isZenArticle ? "/articles-zen" : "/articles-conseils"}
      />
    );
  };

  const save = async () => {
    const {
      title,
      description,
      afterCancer,
      categoryId,
      color,
      duration,
      cover,
      videoCover,
      feelings,
      healthConditions,
      labels,
    } = article;
    if (!title) return triggerError(`Veuillez indiquer un titre`);
    if (!categoryId) return triggerError(`Veuillez indiquer une catégorie`);
    await desactivateUblo();
    const sections = Array.from(contentRef.current.querySelectorAll("section"));
    const content = sections.map((section) => section.outerHTML).join("");
    // const carousels = Array.from(carouselsRef.current.querySelectorAll('.carousel'))
    // const thumbnails = serializeCarousels(carousels)
    const thumbnails = [];
    const isCreation = id === "creation";
    const { id: newId } = isCreation
      ? await API.createArticle(
          token,
          categoryId,
          title,
          description,
          afterCancer,
          content,
          color,
          duration,
          cover,
          videoCover,
          feelings,
          healthConditions,
          thumbnails,
          labels
        )
      : await API.updateArticle(
          token,
          id,
          categoryId,
          title,
          description,
          afterCancer,
          content,
          color,
          duration,
          cover,
          videoCover,
          feelings,
          healthConditions,
          thumbnails,
          labels
        );
    if (!id) {
      await activateUblo();
      return triggerError(`Une erreur est survenue, veuillez réessayer`);
    }
    if (isCreation && newId) {
      const dest = isZenArticle ? "article-zen" : "article";
      navigate(`/${dest}/${newId}`, { type });
    }
    const status = isCreation ? "créé" : "modifié";
    triggerSuccess(`Article "${title}" ${status}`);
    await activateUblo();
  };

  const setColor = (color) => setArticle({ ...article, color });

  const onCategoryChange = ({ target }) => {
    const { value: categoryId } = target;
    const [{ title: category }] = categories.filter(
      ({ id }) => id === categoryId
    );
    setArticle({ ...article, categoryId, category });
  };

  const onDurationChange = ({ target }) => {
    const { value: duration } = target;
    setArticle({ ...article, duration });
  };

  const onVideoCoverChange = ({ target }) => {
    setArticle({ ...article, videoCover: target.value });
  };

  const getDurationText = (value) => `${value}mn`;
  const getDurationLabel = (value) =>
    durations.findIndex((mark) => mark.value === value) + 1;

  const renderTag = (tag, type) => (
    <React.Fragment key={`checkbox-category-${Utils.slugify(tag.label)}`}>
      <Box sx={classes.sidebarBox}>
        <Tags
          article={article}
          setArticle={setArticle}
          tag={tag}
          type={type}
          tagOpen={tagOpen}
          setTagOpen={setTagOpen}
        />
      </Box>
      <Divider />
    </React.Fragment>
  );

  const renderCategory = () => (
    <FormControl sx={classes.articleSelect} variant="standard">
      <InputLabel id="input-category">Catégorie</InputLabel>
      <Select
        labelId="input-category"
        defaultValue=""
        id="input-category-select"
        value={article?.categoryId || ""}
        onChange={onCategoryChange}
      >
        {categories.map(renderCategories)}
      </Select>
    </FormControl>
  );

  const renderCategories = ({ title, id }) => (
    <MenuItem key={`input-category-item-${id}`} value={id}>
      {title}
    </MenuItem>
  );

  const renderSidebar = () => (
    <Sidebar>
      <Box sx={classes.sidebarBox}>
        <TextField
          sx={classes.articleTitle}
          label="Titre"
          value={article?.title || ""}
          onChange={onTitleChange(article)}
          variant="standard"
          helperText="Nombre de caractères suggérés : 50"
          multiline
        />
        {isZenArticle && (
          <TextField
            sx={classes.articleDescription}
            label="Description"
            value={article?.description || ""}
            onChange={onDescriptionChange(article)}
            variant="standard"
            helperText={`${
              (article?.description || "")?.length
            }/140 caractères`}
            multiline
            inputProps={{ maxLength: 140 }}
          />
        )}
        {categories.length > 0 && renderCategory()}
        <FormControlLabel
          label="Article après cancer"
          sx={classes.articleAfterCancer}
          control={
            <Checkbox
              checked={!!article?.afterCancer}
              onChange={onAfterCancerChange(article)}
            />
          }
        />
        {labels && (
          <Autocomplete
            multiple
            freeSolo
            options={labels}
            value={article?.labels || []}
            onChange={onLabelsChange(article)}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={classes.organisationTitle}
                label="Labels"
                variant="standard"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        )}
      </Box>
      {isZenArticle && (
        <>
          <Divider />
          <Box sx={classes.sidebarBox}>
            <FormControl sx={classes.articleDuration} variant="standard">
              <Typography sx={classes.toolTitle}>Durée</Typography>
              <Slider
                sx={classes.slider}
                value={article?.duration || 5}
                getAriaValueText={getDurationText}
                valueLabelFormat={getDurationLabel}
                step={null}
                valueLabelDisplay="off"
                marks={durations}
                onChange={onDurationChange}
                min={5}
                max={80}
              />
            </FormControl>
          </Box>
        </>
      )}
      <Divider />
      <Box sx={classes.sidebarBox}>
        <Box sx={classes.articlePictures}>
          <PictureInsertion
            title="Image de couverture"
            src={article?.cover}
            fileChanged={coverChanged}
            height={coverHeight}
            width={coverWidth}
            buttonMode
          />
        </Box>
        {isZenArticle && (
          <TextField
            label="Vidéo de couverture"
            value={article?.videoCover || ""}
            onChange={onVideoCoverChange}
            variant="standard"
            placeholder="Exemple : dQw4w9WgXcQ"
            helperText="Surcharge l'image de couverture"
            InputProps={{
              endAdornment: (
                <Tooltip
                  title="Code à récupérer dans l'url de la vidéo YouTube souhaitée après 'v='"
                  placement="left"
                >
                  <InputAdornment position="end">
                    <HelpOutline />
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
        )}
      </Box>
      {!isZenArticle && (
        <>
          <Divider />
          <Box sx={classes.sidebarBox}>
            <Typography sx={classes.toolTitle}>Couleur de l'article</Typography>
            {article && (
              <ColorPicker setColor={setColor} selected={article.color} />
            )}
          </Box>
        </>
      )}
      <Divider />
      {tags.feelings?.map((tag) => renderTag(tag, "feelings"))}
      {tags.healthConditions?.map((tag) => renderTag(tag, "healthConditions"))}
    </Sidebar>
  );

  const renderDurationPreview = () => {
    const duration = article?.duration || 5;
    if (!duration) return null;
    const text = duration > 60 ? `> 60` : duration;
    return `${text}min`;
  };

  const actionsProps = { save, cancel };

  const isSaveButtonDisabled = !article?.title || !article?.categoryId;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.inner}>
        <Card
          ListActions={Actions}
          actionsProps={actionsProps}
          isSaveButtonDisabled={isSaveButtonDisabled}
          innerStyles={{ width: "auto" }}
        >
          <Box sx={classes.articleCard}>
            {isZenArticle ? (
              <Box sx={classes.articleHeader}>
                <Typography sx={classes.articleHeaderTitle}>
                  {article?.title}
                </Typography>
                <ArticleHeaderVisual
                  cover={article?.cover}
                  videoCover={article?.videoCover}
                />
                <Typography sx={classes.articleHeaderCategory}>
                  {article?.category} - {renderDurationPreview()}
                </Typography>
              </Box>
            ) : (
              <ArticleCoverPreview
                article={article || initialArticle(isZenArticle)}
              />
            )}
            {article && (
              <UbloZone
                ref={contentRef}
                defaultContent="rich-text"
                content={article.content}
              />
            )}
            {/* <Carousels ref={carouselsRef} carousels={carousels} setCarousels={setCarousels} /> */}
          </Box>
        </Card>
      </Box>
      {renderSidebar()}
    </Box>
  );
}

function ArticleHeaderVisual({ cover, videoCover }) {
  const theme = useTheme();
  const classes = styles(theme);
  if (!cover && !videoCover) return <Box sx={classes.articleHeaderCover} />;
  if (videoCover) {
    const videoUrl = `https://www.youtube.com/embed/${videoCover}`;
    return (
      <Box sx={classes.articleHeaderCover}>
        <iframe
          src={videoUrl}
          frameborder="0"
          width="100%"
          height="100%"
          title={videoCover}
        />
      </Box>
    );
  }
  return <Box component="img" sx={classes.articleHeaderCover} src={cover} />;
}

function styles(theme) {
  return {
    root: {
      display: "flex",
    },
    inner: {
      flex: "1 1 100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fafafa",
      "& > div": {
        padding: "25px 25px 65px 25px",
      },
      ...theme.scrollbar,
    },
    sidebarBox: {
      display: "flex",
      flexDirection: "column",
      padding: "15px",
    },
    articleTitle: {
      width: "100%",
      marginBottom: "5px",
    },
    articleDescription: { width: "100%", marginBottom: "20px" },
    articleCard: {
      position: "relative",
      width: "345px",
      padding: "25px",
      boxSizing: "content-box",
    },
    articleHeaderTitle: {
      marginBottom: "15px",
      fontFamily: "var(--title-font)",
      fontSize: "var(--h2)",
    },
    articleAfterCancer: {
      marginTop: "15px",
    },
    articleHeaderCover: {
      width: "345px",
      height: "200px",
      borderRadius: "25px",
      backgroundColor: "var(--grey-100)",
      aspectRatio: "16 / 9",
      overflow: "hidden",
    },
    articleHeaderCategory: {
      margin: "15px 0 30px 0",
      fontSize: "var(--label)",
      textTransform: "uppercase",
      color: "var(--grey-400)",
      fontWeight: "700",
    },
    articleSelect: {
      width: "100%",
    },
    articleDuration: {
      width: "100%",
      paddingBottom: "12px",
    },
    articlePictures: {
      display: "flex",
    },
    toolTitle: {
      color: theme.palette.grey[600],
      marginBottom: "10px",
    },
    slider: {
      width: "calc(100% - 10px)",
      padding: "8px 0",
      marginLeft: 1,
      "& span": {
        fontSize: "var(--label)",
      },
    },
  };
}

function initialArticle(isZenArticle) {
  return {
    title: "",
    content: "",
    afterCancer: false,
    categoryId: "",
    color: "",
    duration: isZenArticle ? 5 : undefined,
    cover: isZenArticle
      ? ""
      : "https://ublo-file-manager.valraiso.net/assets/moncarnetderoute/167_167/PointInterro%201-10.png",
    feelings: [],
    healthConditions: [],
  };
}
