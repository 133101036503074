import * as React from "react";
import { useLocation } from "react-router-dom";
import { Collapse, Divider, List, ListSubheader } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "./menu-item";
import { useAuth } from "../hooks/use-auth";
import { getNavigation } from "../navigation";

export default function Menu({ feelingTypes }) {
  const [open, setOpen] = React.useState();
  const [selected, setSelected] = React.useState();
  const theme = useTheme();
  const classes = styles(theme);
  const { pathname } = useLocation();

  const { user } = useAuth();
  const isSuperAdmin = user.superadmin;
  const navigation = getNavigation(isSuperAdmin, feelingTypes);
  const { adminNavigation, superadminNavigation } = navigation.reduce(
    (acc, item) => {
      return item.superadmin
        ? { ...acc, superadminNavigation: [...acc.superadminNavigation, item] }
        : { ...acc, adminNavigation: [...acc.adminNavigation, item] };
    },
    { adminNavigation: [], superadminNavigation: [] }
  );

  const handleClick = (slug, menus, sub) => {
    if (menus && menus.length > 0) {
      const [{ slug: menuSlug }] = menus;
      setSelected(menuSlug);
      setOpen(slug);
      return;
    }
    setSelected(slug);
    !sub && setOpen(undefined);
  };

  const handleSubClick = (slug) => handleClick(slug, undefined, true);

  React.useEffect(() => {
    if (pathname !== "/") setSelected(pathname.replace("/", ""));
  }, [pathname]);

  React.useEffect(() => {
    if (selected) {
      navigation.forEach((menu) => {
        const { slug, menus = [] } = menu;
        menus.forEach((submenu) => {
          const { slug: subSlug } = submenu;
          if (subSlug === selected) setOpen(slug);
        });
      });
    }
  }, [navigation, selected]);

  const renderSubMenus = (menus, slug) => (
    <>
      <KeyboardArrowDownIcon
        sx={
          open === slug
            ? { ...classes.chevron, ...classes.selectedChevron }
            : classes.chevron
        }
      />
      <Collapse in={open === slug} timeout="auto" unmountOnExit>
        <List component="div" sx={classes.nestedList} disablePadding>
          {menus.reduce((acc, { title, slug, hidden }) => {
            const styles =
              selected === slug
                ? { ...classes.selectedItem, ...classes.nestedItem }
                : classes.nestedItem;
            const NewItem = (
              <MenuItem
                title={title}
                key={title}
                sx={styles}
                to={`/${slug}`}
                selected={selected === slug}
                onClick={() => handleSubClick(slug)}
              />
            );
            return !hidden ? [...acc, NewItem] : acc;
          }, [])}
        </List>
      </Collapse>
    </>
  );

  return (
    <>
      <List>
        {adminNavigation.reduce((acc, { title, menus, slug, hidden, Icon }) => {
          const hasMenus = menus && menus.length > 0;
          const NewItem = (
            <Box key={title} sx={hasMenus ? classes.entryWithMenus : {}}>
              <MenuItem
                Icon={Icon}
                title={title}
                to={`/${hasMenus ? menus[0].slug : slug}`}
                sx={selected === slug && classes.selectedItem}
                selected={selected === slug}
                onClick={() => handleClick(slug, menus)}
              />
              {hasMenus && renderSubMenus(menus, slug)}
            </Box>
          );
          return !hidden ? [...acc, NewItem] : acc;
        }, [])}
      </List>
      {!!isSuperAdmin && (
        <>
          <Divider />
          <List>
            <ListSubheader component="div">Espace Superadmin</ListSubheader>
            {superadminNavigation.reduce(
              (acc, { title, menus, slug, hidden, Icon }) => {
                const hasMenus = menus && menus.length > 0;
                const NewItem = (
                  <Box key={title} sx={hasMenus ? classes.entryWithMenus : {}}>
                    <MenuItem
                      Icon={Icon}
                      title={title}
                      to={`/${hasMenus ? menus[0].slug : slug}`}
                      sx={selected === slug && classes.selectedItem}
                      selected={selected === slug}
                      onClick={() => handleClick(slug, menus)}
                    />
                    {hasMenus && renderSubMenus(menus, slug)}
                  </Box>
                );
                return !hidden ? [...acc, NewItem] : acc;
              },
              []
            )}
          </List>
        </>
      )}
    </>
  );
}

function styles(theme) {
  return {
    entryWithMenus: {
      position: "relative",
      marginBottom: 0,
    },
    nestedList: {
      backgroundColor: theme.palette.theming.grey.light,
      borderRadius: theme.radiuses.small,
    },
    nestedItem: {
      paddingLeft: theme.spacing(4),
      "&:first-of-type": {
        marginTop: 0,
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
    selectedItem: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white",
      "&>svg": {
        fill: "currentColor",
        height: 18,
        width: 18,
      },
    },
    chevron: {
      position: "absolute",
      top: "11px",
      right: "10px",
      transition: "transform 160ms",
      pointerEvents: "none",
      touchAction: "none",
    },
    selectedChevron: {
      transform: "rotate(0.5turn)",
    },
  };
}
