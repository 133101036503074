import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "var(--content-font)",
  },
  palette: {
    primary: {
      main: "#7ccacc",
    },
    secondary: {
      main: "#ed88ae",
    },
    components: {
      blue: {
        light: "#b9e4e4",
        main: "#7ccacc",
        dark: "#077375",
      },
      orange: "#ec9477",
      rose: "#ed88ae",
      magenta: "#a160fb",
      beige: "#dbc7b7",
    },
    theming: {
      grey: {
        extralight: "#f6f7fb",
        light: "#efefef",
        grey: "#d4d4d4",
        darkgrey: "#3f414e",
      },
      postit: "#fff6A4",
      white: "#ffffff",
      black: "#171e30",
    },
    gradient: {
      primary: {
        main: "linear-gradient(180deg, #7ccacc 0%, #ffffff 100%)",
      },
      secondary: {
        main: "linear-gradient(180deg, #ec9477 0%, #ffffff 100%)",
      },
    },
  },
  radiuses: {
    small: "6px",
  },
  components: {
    // MuiCardHeader: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '1rem'
    //     }
    //   }
    // }
  },
  scrollbar: {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      backgroundColor: "rgba(255, 255, 255, 0.15)",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
    },
    "&::-webkit-scrollbar-thumb": {
      cursor: "pointer",
      backgroundColor: "#d3d3d3",
      transition: "background-color 160ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#7ccacc",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "rgba(0, 0, 0, 0.015)",
    },
  },
});

export default theme;
