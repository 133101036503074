import * as React from "react";
import { useAuth } from "../../hooks/use-auth";
import { useAdminContext } from "../../hooks/use-admin-context";
import * as API from "../../services/api";
import Card from "../card";
import List from "../list";
import RemoveModal from "./common/remove-modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";

const messages = {
  deleteModal: "Supprimer un utilisateur",
  deleteConfirm: "Etes-vous sur de vouloir supprimer cet utilisateur ?",
  deleteSuccess: "Utilisateur supprimé",
};

export default function AppUsers({ Icon }) {
  const [users, setUsers] = React.useState();
  const [ready, setReady] = React.useState(false);
  const classes = styles();
  const { token } = useAuth();
  const { openModal } = useAdminContext();

  React.useEffect(() => {
    const get = async () => {
      const result = await API.getAppUserList(token);
      setUsers(result);
      setReady(true);
    };
    get();
    return () => setUsers(undefined);
  }, [token]);

  const remove = (id) => async () => {
    const { status } = await API.removeAppUser(token, id);
    if (status === 200) {
      setUsers(users.filter((user) => user.id !== id));
      return true;
    }
  };

  const onRemoveButtonClick = (id) =>
    openModal(<RemoveModal remove={remove(id)} messages={messages} />);

  const columns = [
    { field: "id", hidden: true, headerName: "id" },
    { field: "pseudo", headerName: "Pseudo" },
    { field: "firstName", headerName: "Prénom" },
  ];

  const actions = [{ Icon: DeleteIcon, callback: onRemoveButtonClick }];

  return (
    <Box sx={classes.root}>
      <Card Icon={Icon} title="Gestion des utilisateurs de l'application">
        <List
          loading={users === undefined}
          columns={columns}
          rows={users}
          rowActions={actions}
          ready={ready}
        />
      </Card>
    </Box>
  );
}

function styles() {
  return {
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
}
