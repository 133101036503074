import * as React from "react";
import { Link } from "react-router-dom";
import { ListItemButton, ListItemText } from "@mui/material";
import { useTheme } from "@mui/styles";

export default function MenuItem({ title, Icon, sx, ...props }) {
  const theme = useTheme();
  const classes = styles(theme);
  const itemClasses = sx ? { ...classes.item, ...sx } : classes.item;
  return (
    <ListItemButton component={Link} to={"/"} sx={itemClasses} {...props}>
      {Icon && <Icon />}
      <ListItemText primary={title}></ListItemText>
    </ListItemButton>
  );
}

function styles(theme) {
  return {
    item: {
      margin: "6px 0",
      borderRadius: theme.radiuses.small,
      gap: "10px",
      "&>svg": {
        height: 18,
        width: 18,
        fill: theme.palette.components.blue.main,
      },
    },
  };
}
