import * as React from "react";
import { TextField, Select, MenuItem } from "@mui/material";
import Modal from "../../modal";
import { useAdminContext } from "../../../hooks/use-admin-context";

function EditModal({ item, callback, types, kinds, title }, ref) {
  const [categoryTitle, setCategoryTitle] = React.useState(item.title);
  const [typeId, setTypeId] = React.useState(item.typeId);
  const [kind, setKind] = React.useState(item.kind || kinds?.[0]?.code);
  const { closeModal } = useAdminContext();
  const classes = styles();

  const cancel = () => closeModal();

  const confirm = () => {
    callback({ ...item, title: categoryTitle, typeId, kind });
    closeModal();
  };

  const handleTitleChange = (e) => setCategoryTitle(e.target.value);
  const handleTypeChange = (e) => setTypeId(e.target.value);
  const handleKindChange = (e) => setKind(e.target.value);

  const actions = [
    {
      title: "Annuler",
      color: "secondary",
      variant: "text",
      onClick: cancel,
    },
    { title: "Confirmer", color: "primary", onClick: confirm },
  ];

  return (
    <Modal title={title} actions={actions} ref={ref}>
      <TextField
        sx={classes.textfield}
        label="Titre"
        value={categoryTitle}
        onChange={handleTitleChange}
        variant="standard"
      />
      <Select
        sx={classes.select}
        labelId="input-category"
        label="Catégorie"
        defaultValue={typeId}
        id="input-category-select"
        value={typeId}
        onChange={handleTypeChange}
        variant="standard"
      >
        {types.map(({ title, id }) => (
          <MenuItem key={`input-article-category-type-${id}`} value={id}>
            {title}
          </MenuItem>
        ))}
      </Select>
      {typeId === 2 && (
        <Select
          sx={classes.select}
          labelId="input-kind"
          label="Sous Catégorie"
          id="input-category-kind-select"
          value={kind}
          onChange={handleKindChange}
          variant="standard"
        >
          {kinds.map(({ code, label }) => (
            <MenuItem key={`input-article-category-kind-${code}`} value={code}>
              {label}
            </MenuItem>
          ))}
        </Select>
      )}
    </Modal>
  );
}

export default React.forwardRef(EditModal);

function styles() {
  return {
    textfield: {
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
    },
    select: {
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
    },
  };
}
