import * as React from "react";
import {
  Box,
  Typography,
  Checkbox as MUICheckbox,
  FormControlLabel,
  Collapse,
  Badge,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function Tags({
  article,
  setArticle,
  tag,
  type,
  tagOpen,
  setTagOpen,
}) {
  const theme = useTheme();
  const classes = styles(theme);
  const { code, label, items } = tag;

  const { ids, countChecked } = items.reduce(
    (acc, { id }) =>
      article[type].includes(id)
        ? { ids: [...acc.ids, id], countChecked: acc.countChecked + 1 }
        : acc,
    {
      ids: [],
      countChecked: 0,
    }
  );
  const isAllChecked = items.length === countChecked;

  const onTagChange = (id) => () => {
    const current = article[type];
    if (current.includes(id)) {
      setArticle({ ...article, [type]: current.filter((i) => i !== id) });
    } else {
      setArticle({ ...article, [type]: [...current, id] });
    }
  };

  const onCheckAllChange = () => {
    const current = article[type];
    if (isAllChecked) {
      setArticle({
        ...article,
        [type]: current.filter((i) => !ids.includes(i)),
      });
    } else {
      const itemsIds = items.map(({ id }) => id);
      setArticle({
        ...article,
        [type]: [...current, ...itemsIds.filter((id) => !ids.includes(id))],
      });
    }
  };

  const openTag = () => setTagOpen(tagOpen === code ? undefined : code);

  const checkAllBoxLabel = isAllChecked ? "Tout décocher" : "Tout cocher";

  const renderCheckAllBox = () => (
    <Checkbox checked={isAllChecked} onChange={onCheckAllChange} />
  );
  const renderCheckbox = (id) => (
    <Checkbox checked={article[type].includes(id)} onChange={onTagChange(id)} />
  );

  const renderOptions = () =>
    items.map(({ id, label }) => (
      <FormControlLabel
        key={`checkbox-${id}`}
        control={renderCheckbox(id)}
        label={label}
      />
    ));

  return (
    <>
      <Box sx={classes.listExpand} component="button" onClick={openTag}>
        <Typography>
          {label}{" "}
          <Badge
            badgeContent={countChecked}
            color="secondary"
            sx={classes.listBadge}
          />
        </Typography>
        <ExpandMore />
      </Box>
      <Collapse in={tagOpen === code} timeout="auto" unmountOnExit>
        <Box sx={classes.listOptions}>
          <FormControlLabel
            control={renderCheckAllBox()}
            label={checkAllBoxLabel}
          />
          {renderOptions()}
        </Box>
      </Collapse>
    </>
  );
}

function styles(theme) {
  return {
    listExpand: {
      display: "flex",
      justifyContent: "space-between",
      color: theme.palette.grey[600],
      cursor: "pointer",
      "&:focus-visible p": {
        fontWeight: "700",
      },
    },
    listExpandIcon: {
      transition: "transform 160ms ease-in-out",
    },
    listExpandIconOpen: {
      transform: "rotate(180deg)",
    },
    listOptions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "10px",
    },
    listBadge: {
      right: -3,
      padding: "0 4px",
      "& > span": {
        color: theme.palette.background.paper,
        fontWeight: 700,
      },
    },
  };
}

function Checkbox({ ...props }) {
  return <MUICheckbox color="primary" {...props} />;
}
