import * as React from "react";
import { Box } from "@mui/material";
import * as Config from "../../services/config";

export default function Plausible({ filter = "" }) {
  const classes = styles();

  const iframeUrl = Config.withConfiguration((config) => config.plausible);
  return (
    <Box sx={classes.container}>
      <iframe
        plausible-embed="true"
        src={`${iframeUrl}&embed=true&theme=light&${filter}`}
        loading="lazy"
        title="Statistiques"
      />
      <script async src="https://plausible.io/js/embed.host.js"></script>
    </Box>
  );
}

function styles() {
  return {
    container: {
      position: "relative",
      width: "100%",
      height: "100%",
      "& > iframe": {
        position: "absolute",
        left: 16,
        width: "calc(100% - 32px)",
        height: "100%",
      },
    },
  };
}
