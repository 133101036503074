import * as React from "react";
import { Fade, Modal, Snackbar, Alert, DialogContent } from "@mui/material";
import { useTheme } from "@mui/styles";
import Loader from "../components/loader";
import useUblo from "./use-ublo";

const AdminContext = React.createContext();

export function useAdminContext() {
  const context = React.useContext(AdminContext);
  if (!context)
    throw new Error(
      `useAdminContext must be used within a AdminContextProvider`
    );
  return context;
}

export function AdminContextProvider(props) {
  const [loader, setLoader] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({ open: false });
  const [modal, setModal] = React.useState({ open: false, children: <></> });
  const {
    activateUblo,
    desactivateUblo,
    refreshUblo,
    getUbloMode,
    createUbloElement,
  } = useUblo();
  const theme = useTheme();
  const classes = styles(theme);

  const defaultSnackbarProps = {
    message: "",
    onClose: () => setSnackbar(() => ({ open: false })),
    autoHideDuration: 5000,
    TransitionComponent: Fade,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  };

  const defaultModalProps = {
    onClose: () => setModal(() => ({ open: false })),
    disableAutoFocus: true,
  };

  const triggerError = (message) =>
    setSnackbar({
      message,
      open: true,
      severity: "error",
    });

  const triggerSuccess = (message) =>
    setSnackbar({
      message,
      open: true,
      severity: "success",
    });

  const openModal = (children) =>
    setModal({
      open: true,
      children,
      sx: classes.modal,
    });

  const closeModal = () => setModal({ open: false });

  // todo : améliorer l'activation d'ublo
  const _activateUblo = async () => {
    setTimeout(activateUblo, 200);
  };

  return (
    <AdminContext.Provider
      value={{
        loader,
        setLoader,
        snackbar,
        setSnackbar,
        modal,
        setModal,
        openModal,
        closeModal,
        triggerError,
        triggerSuccess,
        activateUblo: _activateUblo,
        desactivateUblo,
        refreshUblo,
        getUbloMode,
        createUbloElement,
      }}
      {...props}
    >
      {props.children}
      <Snackbar key={snackbar.message} {...defaultSnackbarProps} {...snackbar}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
      <Modal {...defaultModalProps} {...modal}>
        <DialogContent sx={classes.modalInner}>{modal.children}</DialogContent>
      </Modal>
      {loader && <Loader />}
    </AdminContext.Provider>
  );
}

function styles(theme) {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "visible",
    },
    modalInner: {
      maxWidth: "420px",
      overflow: "visible",
      "& .MuiPaper-root": {
        borderRadius: theme.radiuses.small,
        boxShadow: "none",
        overflow: "visible",
      },
      "& .MuiTypography-root": {
        textAlign: "left",
        fontFamily: "var(--title-font)",
        overflow: "visible",
      },
    },
  };
}
