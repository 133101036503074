import * as React from "react";
import * as API from "../../services/api";
import ArticleList from "./lists/article";

const messages = {
  title: "Gestion des articles Conseils",
  addButton: "Ajouter un article",
  deleteModal: "Supprimer un article",
  deleteSuccess: "Article supprimé",
};

const additionalColumns = [
  { field: "type", hidden: true, headerName: "Type" },
  { field: "categoryId", hidden: true, headerName: "Catégorie id" },
  { field: "category", headerName: "Catégorie" },
];

export default function Articles({ Icon }) {
  return (
    <ArticleList
      Icon={Icon}
      get={get}
      remove={remove}
      route="/article"
      messages={messages}
      additionalColumns={additionalColumns}
    />
  );
}

function get(token) {
  return API.getArticles(token, 1);
}

function remove(token, id) {
  return API.removeArticle(token, id, 1);
}
