import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

export default function ArticleCoverPreview({ article }) {
  const theme = useTheme();
  const classes = styles(theme);
  return (
    <Box
      sx={classes.articleCoverPreview}
      style={{ backgroundColor: article.color }}
    >
      <Box
        component="img"
        sx={classes.articleCoverPreviewPicture}
        src={article.cover}
      />
      <Typography sx={classes.articleCoverPreviewCategory}>
        {article.category}
      </Typography>
      <Typography sx={classes.articleCoverPreviewTitle}>
        {article.title}
      </Typography>
    </Box>
  );
}

function styles(theme) {
  return {
    articleCoverPreview: {
      position: "relative",
      width: "345px",
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: "30px",
      padding: "15px",
      color: "#ffffff",
      backgroundColor: theme.palette.components.blue.main,
      borderRadius: "25px",
      overflow: "hidden",
    },
    articleCoverPreviewPicture: {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      opacity: "0.3",
      poiterEvents: "none",
      touchActions: "none",
      overflow: "hidden",
    },
    articleCoverPreviewCategory: {
      position: "relative",
      marginBottom: "10px",
      fontSize: "var(--label)",
      fontFamily: "var(--content-font)",
    },
    articleCoverPreviewTitle: {
      position: "relative",
      margin: "",
      fontFamily: "var(--title-font)",
      fontSize: "calc(var(--h2) * 0.7)",
    },
  };
}
