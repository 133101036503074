import * as React from "react";
import { Checkbox } from "@mui/material";
import List from "../list";
import Card from "../card";
import EditModal from "./quotes/edit-modal";
import RemoveModal from "./common/remove-modal";
import ListActions from "./list-actions/quotes";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../../hooks/use-auth";
import { useAdminContext } from "../../hooks/use-admin-context";
import * as API from "../../services/api";

const messages = {
  deleteModal: "Supprimer une citation",
  deleteConfirm: "Etes-vous sur de vouloir supprimer cette citation ?",
  deleteSuccess: "Citation supprimée",
};

export default function Quotes({ Icon }) {
  const [quotes, setQuotes] = React.useState([]);
  const [ready, setReady] = React.useState(false);
  const classes = styles();
  const { token } = useAuth();
  const { openModal, triggerError, triggerSuccess, setLoader } =
    useAdminContext();

  React.useEffect(() => {
    const get = async () => {
      const result = await API.getQuotes(token);
      setQuotes(result);
      setReady(true);
    };
    get();
    return () => setQuotes(undefined);
  }, [token]);

  const update = async ({ id, author, content, enabled }, checked) => {
    setLoader(true);
    const newQuotes = await API.updateQuote(
      token,
      id,
      author,
      content,
      checked ?? enabled
    );
    setQuotes(newQuotes);
    triggerSuccess("Citation mise à jour avec succès");
    setLoader(false);
  };

  const remove = (id) => async () => {
    const newQuotes = await API.removeQuote(token, id);
    setQuotes(newQuotes);
    return true;
  };

  const onRemoveButtonClick = (id) =>
    openModal(<RemoveModal remove={remove(id)} messages={messages} />);
  const onWriteButtonClick = (id) => {
    const [quote] = quotes.filter((quote) => quote.id === id);
    openModal(
      <EditModal add={quote} callback={update} title="Éditer une citation" />
    );
  };

  const renderCell = (row) => {
    const enabled = Boolean(row.enabled);
    const onChange = (_, checked) => update(row, checked);
    return (
      <Checkbox
        sx={classes.checkbox}
        checked={enabled}
        onChange={onChange}
        color="primary"
      />
    );
  };

  const addQuote = async (add) => {
    setLoader(true);
    const { author, content, enabled, position } = add;
    if (!author) {
      setLoader(false);
      return triggerError("Veuillez nommer votre entrée.");
    }
    if (!content) {
      setLoader(false);
      return triggerError("Veuillez écrire une citation.");
    }
    const newQuotes = await API.createQuote(
      token,
      author,
      content,
      enabled,
      position
    );
    setQuotes(newQuotes);
    triggerSuccess(`Nouvelle citation ajoutée.`);
    setLoader(false);
  };

  const columns = [
    { field: "id", hidden: true, headerName: "id" },
    { field: "author", headerName: "Auteur", width: 150 },
    { field: "content", headerName: "Citation" },
    { field: "enabled", headerName: "Visible", width: 73, renderCell },
  ];

  const rowActions = [
    { Icon: EditIcon, callback: onWriteButtonClick },
    { Icon: DeleteIcon, callback: onRemoveButtonClick },
  ];

  const actionsProps = { quotes, callback: update, addQuote };

  return (
    <Card
      Icon={Icon}
      title="Gestion des citations"
      ListActions={ListActions}
      actionsProps={actionsProps}
    >
      <List
        loading={quotes === undefined}
        columns={columns}
        rows={quotes}
        rowActions={rowActions}
        ready={ready}
      />
    </Card>
  );
}

function styles() {
  return {
    checkbox: {
      justifySelf: "center",
    },
  };
}
