import * as React from "react";
import useLocalStorage from "./use-local-storage";
import * as Token from "../services/token";

const AuthContext = React.createContext();

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export function AuthProvider(props) {
  const [user, setUser] = useLocalStorage("user", undefined);
  const [token, setToken] = useLocalStorage("token", undefined);

  const login = async (token) => {
    setUser(Token.parse(token));
    setToken(token);
  };

  const logout = () => {
    setToken(undefined);
    setUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        token,
      }}
      {...props}
    />
  );
}
