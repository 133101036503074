export function parse(token) {
  try {
    const user = JSON.parse(b64DecodeUnicode(token.split(".")[1]));
    return user;
  } catch (e) {
    throw Error("Jeton de sécurité invalide");
  }
}

function b64DecodeUnicode(str) {
  return decodeURIComponent(escape(atob(str)));
}
