import * as React from "react";
import { TextField } from "@mui/material";
import Modal from "../../modal";
import { useAdminContext } from "../../../hooks/use-admin-context";
import * as API from "../../../services/api";
import { useAuth } from "../../../hooks/use-auth";

function EditModal({ add, callback, title }, ref) {
  const [word, setWord] = React.useState(add.word);
  const [definition, setDefinition] = React.useState(add.definition);
  const [kind, setKind] = React.useState(add.kind);
  const [, setKinds] = React.useState([]);
  const { token } = useAuth();
  const { closeModal } = useAdminContext();
  const classes = styles();

  const cancel = () => closeModal();

  const confirm = () => {
    callback({ ...add, word, definition, kind });
    closeModal();
  };

  React.useEffect(() => {
    const get = async () => {
      const kinds = await API.getDefinitionKinds(token);
      setKinds(kinds);
    };
    get();
  }, [token]);

  const handleWordChange = (e) => setWord(e.target.value);
  const handleDefinitionChange = (e) => setDefinition(e.target.value);
  const handleKindChange = (e) => setKind(e.target.value);

  const actions = [
    { title: "Annuler", color: "secondary", variant: "text", onClick: cancel },
    { title: "Confirmer", color: "primary", onClick: confirm },
  ];

  return (
    <Modal title={title} actions={actions} ref={ref}>
      <TextField
        sx={classes.textfield}
        label="Mot"
        value={word}
        onChange={handleWordChange}
        variant="standard"
      />
      <TextField
        sx={classes.textfield}
        label="Définition"
        value={definition}
        rows={7}
        onChange={handleDefinitionChange}
        variant="standard"
        multiline
      />
      <TextField
        sx={classes.textfield}
        label="Type"
        value={kind}
        onChange={handleKindChange}
        variant="standard"
      />
    </Modal>
  );
}

function styles() {
  return {
    textfield: {
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
    },
  };
}

export default React.forwardRef(EditModal);
