import * as React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { useTheme } from "@mui/styles";
import { SearchOutlined } from "@mui/icons-material";

export default function List({
  width = 1400,
  height,
  rows = [],
  columns = [],
  rowActions = [],
  columnsOrder,
  disableSearch,
}) {
  const theme = useTheme();
  const classes = styles(theme);

  const orderedColumns = orderColumns(columns, columnsOrder);

  const columnsWithAction =
    rowActions.length > 0
      ? buildColumnsWithActions(orderedColumns, rowActions)
      : orderedColumns;

  const rowsWithAction = rows.reduce((acc, row) => {
    const actions = rowActions.length > 0 ? { rowActions } : {};
    return [...acc, { ...row, ...actions }];
  }, []);

  const [search, setSearch] = React.useState("");

  const normalizeSearch = search
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();

  const filteredRows = rowsWithAction.filter((row) => {
    return Object.values(row).some((value) => {
      const normalizedValue =
        value !== undefined && value !== null
          ? value
              .toString()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .trim()
          : "";
      return normalizedValue.includes(normalizeSearch);
    });
  });

  const onChange = (e) => {
    const { value } = e.target;
    setSearch(value || "");
  };

  const count =
    normalizeSearch.length > 0 && filteredRows.length > 0
      ? `${filteredRows.length} résultat${filteredRows.length > 1 ? "s" : ""}`
      : " ";

  return (
    <Box style={{ width, height }} sx={classes.root}>
      {!disableSearch && (
        <TextField
          sx={classes.search}
          type="search"
          label="Rechercher"
          variant="standard"
          value={search}
          onChange={onChange}
          helperText={count}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      )}
      <TableContainer component={Paper} sx={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnsWithAction.map((column) => {
                const { field, headerName, hidden, width } = column;
                if (hidden) return null;
                return (
                  <TableCell
                    key={field}
                    sx={{ width, ...classes.tableHeaderCell }}
                  >
                    {headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length === 0 && (
              <TableRow>
                <TableCell sx={classes.noResults}>Pas de résultats</TableCell>
              </TableRow>
            )}
            {filteredRows.map((row, i) => {
              const keys = columnsWithAction.map((column) => column.field);
              return (
                <TableRow key={i} data-id={row.id} sx={classes.tableRow}>
                  {keys.map((key) => {
                    const column = columnsWithAction.find(
                      (col) => col.field === key
                    );
                    const isActions = key === "rowActions";
                    const cellContent = isActions
                      ? buildActions(row, rowActions)
                      : row[key];
                    const value = !isActions ? cellContent : undefined;
                    const { renderCell, hidden } = column || row[key];
                    const formatedContent = renderCell?.(row) || cellContent;
                    if (hidden) return null;
                    return (
                      <TableCell
                        key={`${key}-${i}`}
                        data-key={key}
                        sx={classes.tableCell}
                      >
                        <span data-value={value}>{formatedContent}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function styles(theme) {
  return {
    root: {
      position: "relative",
      maxWidth: "100%",
      maxHeight: "100%",
      padding: "15px 15px 0 15px",
    },
    search: {
      position: "absolute",
      bottom: "calc(100% - 24px)",
      right: "16px",
    },
    paper: {
      backgroundColor: "var(--grey-000)",
      borderRadius: theme.radiuses.small,
      boxShadow: "none",
    },
    tableRow: {
      "&:last-child .MuiTableCell-root": {
        border: "none",
      },
    },
    tableHeaderCell: {
      color: "var(--grey-400)",
      borderBottom: "2px solid var(--grey-200)",
    },
    noResults: {
      padding: "20px",
      fontStyle: "italic",
      color: "var(--grey-500)",
      borderBottom: "none",
      whiteSpace: "nowrap",
    },
    tableCell: {
      borderBottom: "2px solid var(--grey-200)",
      '&[data-key="category"] > span': {
        padding: "5px 10px",
        color: "var(--grey-000)",
        borderRadius: "20px",
        whiteSpace: "nowrap",
      },
      // 🤮🤮🤮
      '&[data-key="category"] > span[data-value="Mon entourage"], &[data-key="category"] > span[data-value="Ma bibliothèque"]':
        {
          backgroundColor: "var(--accent)",
        },
      '&[data-key="category"] > span[data-value="Ma vie professionnelle"], &[data-key="category"] > span[data-value="Nos idées recettes"]':
        {
          backgroundColor: "var(--blue)",
        },
      '&[data-key="category"] > span[data-value="Ma vie au quotidien"], &[data-key="category"] > span[data-value="Musiques"]':
        {
          backgroundColor: "var(--dark-blue)",
        },
      '&[data-key="category"] > span[data-value="Prendre soin de moi"], &[data-key="category"] > span[data-value="Mes films"]':
        {
          backgroundColor: "var(--beige)",
        },
      '&[data-key="category"] > span[data-value="Effets des traitements"], &[data-key="category"] > span[data-value="Je fais du sport"]':
        {
          backgroundColor: "var(--orange)",
        },
      '&[data-key="category"] > span[data-value="Tout savoir sur le cancer du sein"], &[data-key="category"] > span[data-value="Podcasts"]':
        {
          backgroundColor: "var(--grey-600)",
        },
      '&[data-key="category"] > span[data-value="Les soins de support"]': {
        backgroundColor: "var(--grey-300)",
      },
      '&[data-key="category"] > span[data-value="L’après cancer"], &[data-key="category"] > span[data-value="Mes tutos et activités détentes"], &[data-key="category"] > span[data-value="Évènements"], &[data-key="category"] > span[data-value="Témoignages"]':
        {
          backgroundColor: "var(--magenta)",
        },
    },
  };
}

function buildColumnsWithActions(columns, rowActions) {
  return [
    ...columns,
    {
      field: "rowActions",
      headerName: "Actions",
      width: rowActions.length * 48 + 20,
    },
  ];
}

function orderColumns(columns, columnsOrder) {
  if (!columnsOrder) return columns;
  return columns.sort((a, b) => {
    const indexA = columnsOrder.indexOf(a.field);
    const indexB = columnsOrder.indexOf(b.field);
    return indexA - indexB;
  });
}

function buildActions(row, rowActions) {
  return rowActions.map((action, i) => {
    const { id } = row;
    const { Icon, callback } = action;
    const onClick = (e) => {
      e.stopPropagation();
      typeof callback === "function" && callback(id);
    };
    return (
      <IconButton key={i} onClick={onClick}>
        <Icon />
      </IconButton>
    );
  });
}
