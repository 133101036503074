import * as React from "react";
import { Checkbox, IconButton } from "@mui/material";
import ListActions from "../list-actions/entry-list";
import List from "../../list";
import Card from "../../card";
import RemoveModal from "../common/remove-modal";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "../../../hooks/use-auth";
import { useAdminContext } from "../../../hooks/use-admin-context";
import * as API from "../../../services/api";

const sortEntries = (entries) =>
  entries.sort((a, b) => a.position - b.position);

export default function EntryList({ Icon, path, label }) {
  const [entries, setEntries] = React.useState([]);
  const [add, setAdd] = React.useState();
  const classes = styles();
  const { token } = useAuth();
  const { openModal, triggerError, triggerSuccess, setLoader } =
    useAdminContext();

  const messages = buildMessages(label);

  React.useEffect(() => {
    const fetcher = async () => {
      const result = await API.getFeelings(token, path);
      const sortedResult = sortEntries(result);
      setEntries(sortedResult);
    };
    fetcher();
    return () => setEntries(undefined);
  }, [token, path]);

  const removeItem = (id) => async () => {
    setLoader(true);
    const [{ position }] = entries.filter((entry) => entry.id === id);

    const newEntries = await API.removeFeeling(token, path, id, position);
    const sorted = sortEntries(newEntries);
    setEntries(sorted);
    setLoader(false);
    return true;
  };

  const onRemoveButtonClick = (id) =>
    openModal(<RemoveModal remove={removeItem(id)} messages={messages} />);

  const updateEnabled = async (row, enabled) => {
    setLoader(true);
    delete row.rowActions;
    const newEffects = await API.updateFeeling(token, path, [
      { ...row, enabled },
    ]);
    const sorted = sortEntries(newEffects);
    setEntries(sorted);
    setLoader(false);
  };

  const onCellChange = (row) => (_, checked) => updateEnabled(row, checked);

  const renderCell = (row) => {
    const enabled = Boolean(row.enabled);
    const onChange = onCellChange(row);
    return (
      <Checkbox
        sx={classes.checkbox}
        checked={enabled}
        onChange={onChange}
        color="primary"
      />
    );
  };

  const changeOrder = async (row, rank = 0) => {
    setLoader(true);
    const { position } = row;
    const shift = position + rank;
    if (shift < 0 || shift > entries.length - 1) return setLoader(false);
    const [swapper] = entries.filter((entry) => entry.position === shift);
    delete swapper.rowActions;
    delete row.rowActions;
    const newEffects = await API.updateFeeling(token, path, [
      { ...row, position: shift },
      { ...swapper, position },
    ]);
    const sortedEntries = sortEntries(newEffects);
    setEntries(sortedEntries);
    setLoader(false);
  };

  const addEntry = async (add) => {
    setLoader(true);
    const { label, enabled, position } = add;
    if (!label) {
      setLoader(false);
      return triggerError("Veuillez nommer votre entrée.");
    }
    const labels = entries.map(({ label }) => label);
    if (labels.includes(label)) {
      setLoader(false);
      return triggerError(messages.addFailure);
    }
    const newEntries = await API.createFeeling(
      token,
      path,
      label,
      enabled,
      position
    );
    const sorted = sortEntries(newEntries);
    setEntries(sorted);
    setAdd(undefined);
    triggerSuccess(messages.addSuccess(label));
    setLoader(false);
  };

  const handleChange = (add) => (e) =>
    setAdd({ ...add, label: e.target.value });
  const toggleEnabled = (add) => () =>
    setAdd({ ...add, enabled: !add.enabled });

  const lowerOrder = (row) => changeOrder(row, -1);
  const upperOrder = (row) => changeOrder(row, 1);

  const renderOrderTools = (row) => {
    const { id, position } = row;
    return (
      <>
        <IconButton
          key={`order-up-${id}`}
          disabled={position >= entries.length - 1}
          onClick={() => upperOrder(row)}
        >
          <ExpandMoreIcon />
        </IconButton>
        <IconButton
          key={`order-down-${id}`}
          disabled={position === 0}
          onClick={() => lowerOrder(row)}
        >
          <ExpandLessIcon />
        </IconButton>
      </>
    );
  };

  const columns = [
    { field: "id", hidden: true, headerName: "id" },
    {
      field: "position",
      headerName: "Ordre",
      width: 130,
      renderCell: renderOrderTools,
    },
    { field: "label", headerName: "Nom" },
    { field: "enabled", headerName: "Visible", width: 73, renderCell },
  ];

  const rowActions = [{ Icon: DeleteIcon, callback: onRemoveButtonClick }];

  const actionsProps = {
    handleChange,
    toggleEnabled,
    add,
    entries,
    setAdd,
    addEntry,
    messages,
  };

  return (
    <Card
      Icon={Icon}
      title={messages.title}
      ListActions={ListActions}
      actionsProps={actionsProps}
    >
      <List
        loading={entries === undefined}
        columns={columns}
        rows={entries}
        rowActions={rowActions}
      />
    </Card>
  );
}

function styles() {
  return {
    checkbox: {
      justifySelf: "center",
    },
  };
}

function buildMessages(label) {
  return {
    title: `Gestion - ${label}`,
    addButton: `Ajouter - ${label}`,
    deleteModal: `Supprimer - ${label}`,
    deleteSuccess: `${label} supprimé`,
    addFailure: `${label} : cet élément existe déjà.`,
    addSuccess: (title) => `${label} - ${title} ajouté.`,
  };
}
