import * as React from "react";
import { Card, CardContent, CardHeader, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import Button from "./ui/button";
import { useAuth } from "../hooks/use-auth";
import { useAdminContext } from "../hooks/use-admin-context";
import * as API from "../services/api";
import { Box } from "@mui/system";

const LOGIN = 0;
const RESET = 1;

const ResetModalContentWithRef = React.forwardRef(ResetModalContent);

export default function Login() {
  const theme = useTheme();
  const classes = styles(theme);
  const { setSnackbar } = useAdminContext();
  const { login } = useAuth();
  const [data, setData] = React.useState({});
  const [mode, setMode] = React.useState(LOGIN);
  const [resetToken, setResetToken] = React.useState();

  const Form = mode === LOGIN ? LoginForm : ResetForm;

  const submitForm = async (update) => {
    const { email, password } = data;
    try {
      const endpoint = update ? API.userUpdatePassword : API.userLogin;
      const { token, status } = await endpoint(email, password, resetToken);
      if (status && status === "reset_password") {
        setMode(RESET);
        setResetToken(token);
      } else if (token) {
        login(token);
      }
    } catch (error) {
      const {
        code = "",
        message = "Une erreur est survenue, veuillez réessayer",
      } = error;
      setSnackbar({
        message: `${code} ${message}`,
        open: true,
        severity: "error",
      });
    }
  };

  return (
    <Box sx={classes.root}>
      <Card sx={classes.form}>
        <CardHeader sx={classes.formHeader} title="Mon Carnet de Route" />
        <CardContent sx={classes.formInner}>
          <Form data={data} setData={setData} submitForm={submitForm} />
        </CardContent>
      </Card>
    </Box>
  );
}

function LoginForm({ data, setData, submitForm }) {
  const theme = useTheme();
  const classes = styles(theme);
  const { setModal } = useAdminContext();

  const onInputChange = (e) => {
    const { value, name } = e.target;
    setData((data) => {
      return { ...data, [name]: value };
    });
  };

  const isFormValid = () => {
    if (!data.email || !data.password) return false;
    if (data.email.trim() === "" || data.password.trim() === "") return false;
    return true;
  };

  const onResetClick = () => {
    setModal({
      open: true,
      children: <ResetModalContentWithRef />,
      className: classes.formModal,
    });
  };

  return (
    <>
      <TextField
        sx={classes.formControl}
        label="Email"
        id="email"
        name="email"
        type="email"
        onChange={onInputChange}
        variant="standard"
        required
        fullWidth
      />
      <TextField
        sx={classes.formControl}
        label="Mot de passe"
        id="password"
        name="password"
        type="password"
        onChange={onInputChange}
        variant="standard"
        required
        fullWidth
      />
      <Button
        sx={classes.formButton}
        disabled={!isFormValid()}
        color="primary"
        variant="contained"
        type="submit"
        onClick={() => submitForm(false)}
        fullWidth
      >
        Connexion
      </Button>
      <Button sx={classes.formButton} onClick={onResetClick}>
        Mot de passe oublié ?
      </Button>
    </>
  );
}

function ResetForm({ setData, submitForm }) {
  const theme = useTheme();
  const classes = styles(theme);
  const [resetData, setResetData] = React.useState({});

  const onInputChange = (e) => {
    const { value, name } = e.target;
    setResetData((data) => ({ ...data, [name]: value }));
    setData((data) => ({ ...data, password: value }));
  };

  const isFormValid = () => {
    if (!resetData.password || !resetData.confirmPassword) return false;
    if (
      resetData.password.trim() === "" ||
      resetData.confirmPassword.trim() === ""
    )
      return false;
    if (resetData.password !== resetData.confirmPassword) return false;
    return true;
  };

  return (
    <>
      <TextField
        sx={classes.formControl}
        label="Nouveau mot de passe"
        id="password"
        name="password"
        type="password"
        onChange={onInputChange}
        variant="standard"
        required
        fullWidth
      />
      <TextField
        sx={classes.formControl}
        label="Confirmer le nouveau mot de passe"
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        onChange={onInputChange}
        variant="standard"
        required
        fullWidth
      />
      <Button
        sx={classes.formButton}
        disabled={!isFormValid()}
        color="primary"
        variant="contained"
        type="submit"
        onClick={() => submitForm(true)}
        fullWidth
      >
        Valider
      </Button>
    </>
  );
}

function ResetModalContent(_, ref) {
  const theme = useTheme();
  const classes = styles(theme);
  const { setModal, setSnackbar } = useAdminContext();
  const [email, setEmail] = React.useState();

  const onInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const submitForm = async () => {
    const { status } = await API.userResetPassword(email);
    if (status === "reset_password") {
      setModal({ open: false });
      setSnackbar({
        message: "Mot de passe réinitialisé, veuillez consulter vos emails",
        open: true,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: "Une erreur est survenue, veuillez réessayer",
        open: true,
        severity: "error",
      });
    }
  };

  return (
    <Card ref={ref}>
      <CardHeader
        sx={classes.formModalHeader}
        title="Réinitialiser mon mot de passe"
      />
      <CardContent sx={classes.formModalInner}>
        <TextField
          sx={classes.formControl}
          label="Email"
          id="email"
          name="email"
          type="email"
          onChange={onInputChange}
          variant="standard"
          required
          fullWidth
        />
        <Button
          sx={classes.formButton}
          disabled={!email || email.trim() === ""}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => submitForm()}
          fullWidth
        >
          Valider
        </Button>
      </CardContent>
    </Card>
  );
}

function styles(theme) {
  return {
    root: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f5f5f5",
    },
    form: {
      width: "100%",
      maxWidth: "400px",
      borderRadius: theme.radiuses.small,
      boxShadow: "none",
    },
    formHeader: {
      padding: "16px 16px 0 16px",
      textAlign: "center",
      "& .MuiTypography-root": {
        fontFamily: "Trocchi",
      },
    },
    formInner: {
      padding: "25px",
    },
    formControl: {
      margin: "10px 0",
    },
    formButton: {
      marginTop: "10px",
    },
    formModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    formModalHeader: {
      padding: "25px 25px 0 25px",
    },
    formModalInner: {
      padding: "0 25px 25px 25px",
    },
  };
}
