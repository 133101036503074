import * as React from "react";
import { useAuth } from "../hooks/use-auth";
import * as Ublo from "../services/ublo";
import * as API from "../services/api";

export default function useUblo() {
  const { token } = useAuth();
  const [loaded, setLoaded] = React.useState(false);

  const desactivateUblo = async () => {
    if (!window.Cms) return;
    await window.Cms.cleanMarkup();
    await window.Cms.desactivate();
  };

  const activateUblo = async () => {
    if (!window.Cms) return;
    await desactivateUblo();
    await window.Cms.analyse();
    window.Cms.activate();
  };

  const refreshUblo = async () => {
    if (!window.Cms) return;
    await window.Cms.analyse();
  };

  const getUbloMode = () => window.CmsConfig.mode;

  const createUbloElement = async (code) => {
    if (!window.Cms) return null;
    await window.Cms.register();
    const { createElement } = window.Cms?.sectionClasses[code];
    if (!createElement) return "";
    const element = createElement(code);
    return element?.outerHTML || "";
  };

  React.useEffect(() => {
    const init = async () => {
      if (window.Cms !== undefined) return;
      const customStructure = await API.getStructure(token);
      await Ublo.load(customStructure);
      const Cms = window.hasOwnProperty("Cms") ? window.Cms : undefined;
      if (Cms) {
        setLoaded(true);
      }
    };
    init();
  }, [token]);

  React.useEffect(() => {
    const start = async () => window.Cms.start();
    loaded && window.Cms && start();
  }, [loaded]);

  return {
    activateUblo,
    desactivateUblo,
    refreshUblo,
    getUbloMode,
    createUbloElement,
  };
}
