import * as React from "react";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import Button from "./ui/button";

export default function Actions({ save, cancel, isSaveButtonDisabled }) {
  const classes = styles();

  return (
    <Box sx={classes.actions}>
      <Button
        sx={classes.button}
        color="secondary"
        variant="contained"
        onClick={cancel}
      >
        <BlockIcon sx={classes.icon} />
        Annuler
      </Button>
      <Button
        sx={classes.button}
        color="primary"
        variant="contained"
        onClick={save}
        disabled={isSaveButtonDisabled}
      >
        <CheckIcon sx={classes.icon} />
        Enregistrer
      </Button>
    </Box>
  );
}

function styles() {
  return {
    actions: {
      position: "fixed",
      right: "285px",
      bottom: "15px",
      display: "flex",
      justifyContent: "space-between",
      margin: "0 15px",
      padding: "0 5px",
      borderRadius: "6px",
      zIndex: "3",
    },
    button: {
      margin: "0 10px",
    },
    icon: {
      marginRight: "10px",
    },
  };
}
