import * as React from "react";
import { Box, Checkbox, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Actions from "../../list-actions";

const initialUser = { email: "", superadmin: false };

export default function ListActions({
  handleChange,
  toggleSuperadmin,
  add,
  setAdd,
  addUser,
}) {
  const classes = styles();

  const createUser = async () =>
    add ? await addUser(add) : setAdd(initialUser);

  const renderTools = () => (
    <>
      <TextField
        sx={classes.textfield}
        autoFocus={true}
        value={add.name}
        onChange={handleChange(add)}
        variant="standard"
      />
      <Box sx={classes.enable}>
        <Checkbox
          checked={add.superadmin}
          color="primary"
          onChange={toggleSuperadmin(add)}
        />
      </Box>
      <Box sx={classes.tools}>
        <IconButton color="primary" onClick={() => addUser(add)}>
          <DoneIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => setAdd(undefined)}>
          <CloseIcon />
        </IconButton>
      </Box>
    </>
  );

  return (
    <Actions
      message="Ajouter un utilisateur"
      action={createUser}
      children={add && renderTools()}
    ></Actions>
  );
}

function styles() {
  return {
    textfield: {
      padding: "0 16px",
      flex: 1,
    },
    enable: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "0 0 84px",
    },
    tools: {
      flex: "0 0 122px",
    },
  };
}
