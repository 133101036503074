import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Divider,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import {
  LocationOnOutlined,
  EmailOutlined,
  LanguageOutlined,
  LocalPhoneOutlined,
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import UbloZone from "../ublo-zone";
import Card from "../card";
import { useAdminContext } from "../../hooks/use-admin-context";
import { useAuth } from "../../hooks/use-auth";
import Sidebar from "../sidebar";
import CancelModal from "./common/cancel-modal";
import PictureInsertion from "./article/picture-insertion";
import Actions from "../page-actions";
import * as API from "../../services/api";
import * as Ublo from "../../services/ublo";

const initialOrganisation = {
  title: "",
  kind: "",
  logo: "",
  description: "",
  address: "",
  email: "",
  phone: "",
  website: "",
  additional_description: "",
};

export default function Organisation() {
  const descriptionRef = React.useRef(null);
  const additionalDescriptionRef = React.useRef(null);
  const [organisation, setOrganisation] = React.useState();
  const [kinds, setKinds] = React.useState([]);
  const { id } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = styles(theme);
  const { openModal, activateUblo, desactivateUblo } = useAdminContext();
  const { triggerError, triggerSuccess } = useAdminContext();

  React.useEffect(() => {
    const get = async () => {
      await desactivateUblo();
      const kinds = await API.getOrganisationsKinds(token);
      setKinds(kinds);
      if (id === "creation") {
        setOrganisation(initialOrganisation);
      } else {
        const organisation = await API.getOrganisation(token, id);
        setOrganisation(organisation);
      }
      await activateUblo();
    };
    get();
  }, [activateUblo, desactivateUblo, id, token]);

  const pictureChanged =
    (picture) =>
    ({ path }) =>
      setOrganisation({
        ...organisation,
        [picture]: `${Ublo.getConfig().cdn}${path}`,
      });
  const coverChanged = pictureChanged("logo");

  const onFieldChange = (field, organisation) => (e, inputValue) => {
    const value = e?.target?.value || inputValue;
    setOrganisation({ ...organisation, [field]: value });
  };

  const cancel = () => openModal(<CancelModal page="/annuaire" />);
  const save = async () => {
    await desactivateUblo();
    const { title, kind, logo, address, email, phone, website } = organisation;
    if (!title) {
      triggerError(`Veuillez indiquer un titre`);
      await activateUblo();
      return;
    }
    const sections = Array.from(
      descriptionRef.current.querySelectorAll("section")
    );
    const content = sections.map((section) => section.outerHTML).join("");
    const additionalSections = Array.from(
      additionalDescriptionRef.current.querySelectorAll("section")
    );
    const additionalContent = additionalSections
      .map((section) => section.outerHTML)
      .join("");
    const isCreation = id === "creation";
    const { id: newId } = isCreation
      ? await API.createOrganisation(
          token,
          title,
          kind,
          logo,
          content,
          address,
          email,
          phone,
          website,
          additionalContent
        )
      : await API.updateOrganisation(
          token,
          id,
          title,
          kind,
          logo,
          content,
          address,
          email,
          phone,
          website,
          additionalContent
        );
    if (!id && !newId) {
      triggerError(`Une erreur est survenue, veuillez réessayer`);
      await activateUblo();
      return;
    }
    const status = isCreation ? "créée" : "modifiée";
    triggerSuccess(`Organisation "${title}" ${status}`);
    await activateUblo();
    if (isCreation && newId) navigate(`/annuaire/${newId}`);
  };

  const renderSidebar = () => (
    <Sidebar>
      <Box sx={classes.sidebarBox}>
        <TextField
          sx={classes.organisationTitle}
          label="Titre"
          value={organisation?.title || ""}
          onChange={onFieldChange("title", organisation)}
          variant="standard"
        />
        <Autocomplete
          freeSolo
          id="kinds-input"
          options={kinds}
          inputValue={organisation?.kind || ""}
          onInputChange={onFieldChange("kind", organisation)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={classes.organisationTitle}
              label="Type"
              variant="standard"
            />
          )}
        />
      </Box>
      <Divider />
      <Box sx={classes.sidebarBox}>
        <Box sx={classes.organisationContent}>
          <PictureInsertion
            title="Logo"
            src={organisation?.logo}
            fileChanged={coverChanged}
            height={100}
            width={100}
            buttonMode
          />
        </Box>
      </Box>
      <Divider />
      <Box sx={classes.sidebarBox}>
        <TextField
          sx={classes.organisationTitle}
          label="Addresse"
          value={organisation?.address || ""}
          onChange={onFieldChange("address", organisation)}
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnOutlined />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={classes.organisationTitle}
          label="Email"
          value={organisation?.email || ""}
          onChange={onFieldChange("email", organisation)}
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={classes.organisationTitle}
          label="Téléphone"
          value={organisation?.phone || ""}
          onChange={onFieldChange("phone", organisation)}
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalPhoneOutlined />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={classes.organisationTitle}
          label="Site internet"
          value={organisation?.website || ""}
          onChange={onFieldChange("website", organisation)}
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageOutlined /> https://
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Divider />
    </Sidebar>
  );

  const actionsProps = { save, cancel };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.inner}>
        <Card
          ListActions={Actions}
          actionsProps={actionsProps}
          innerStyles={{ width: "auto" }}
        >
          <Box sx={classes.organisationHeader}>
            <Box sx={classes.organisationHeaderLeft}>
              {organisation?.logo?.length > 0 ? (
                <Box
                  component="img"
                  src={organisation?.logo}
                  alt={organisation?.title}
                />
              ) : (
                <div />
              )}
            </Box>
            <Box sx={classes.organisationHeaderRight}>
              <span>{organisation?.title}</span>
              <span>{organisation?.kind}</span>
            </Box>
          </Box>
          <Divider sx={{ margin: "0 25px" }} />
          {organisation && (
            <Box sx={classes.organisationCard}>
              <UbloZone
                ref={descriptionRef}
                defaultContent="rich-text"
                content={organisation.description}
              />
            </Box>
          )}
          {organisation?.address?.trim().length > 0 && (
            <Box sx={classes.organisationData}>
              <LocationOnOutlined />
              <span>{organisation?.address}</span>
            </Box>
          )}
          {organisation?.email?.trim().length > 0 && (
            <Box sx={classes.organisationData}>
              <EmailOutlined />
              <span>{organisation?.email}</span>
            </Box>
          )}
          {organisation?.phone?.trim().length > 0 && (
            <Box sx={classes.organisationData}>
              <LocalPhoneOutlined />
              <span>{organisation?.phone}</span>
            </Box>
          )}
          {organisation?.website?.trim().length > 0 && (
            <Box sx={classes.organisationData}>
              <LanguageOutlined />
              <span>{organisation?.website}</span>
            </Box>
          )}
          <Divider sx={{ margin: "0 25px" }} />
          {organisation && (
            <Box sx={classes.organisationCard}>
              <UbloZone
                ref={additionalDescriptionRef}
                content={organisation.additional_description}
              />
            </Box>
          )}
        </Card>
      </Box>
      {renderSidebar()}
    </Box>
  );
}

function styles(theme) {
  return {
    root: {
      display: "flex",
    },
    inner: {
      flex: "1 1 100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fafafa",
      "& > div": {
        padding: "25px 25px 65px 25px",
      },
      ...theme.scrollbar,
    },
    sidebarBox: {
      display: "flex",
      flexDirection: "column",
      padding: "15px",
    },
    organisationTitle: {
      width: "100%",
      marginBottom: "15px",
    },
    organisationCard: {
      position: "relative",
      width: "345px",
      margin: "20px 0",
      padding: "0 25px",
      boxSizing: "content-box",
    },
    organisationHeader: {
      display: "flex",
      alignItems: "stretch",
      padding: "25px 25px 0 25px",
    },
    organisationHeaderLeft: {
      paddingBottom: "20px",
      "& > img, & > div": {
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        boxShadow: "var(--shadow-300)",
      },
    },
    organisationHeaderRight: {
      flex: "1 1 100%",
      display: "flex",
      flexDirection: "column",
      paddingLeft: "25px",
      "& > span:nth-of-type(1)": {
        fontFamily: "var(--title-font)",
        fontSize: "var(--h2)",
      },
      "& > span:nth-of-type(2)": {
        fontSize: "var(--label)",
        color: "var(--grey-400)",
      },
    },
    organisationData: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
      padding: "0 25px",
      "& > svg": {
        marginRight: "16px",
        fill: "var(--grey-500)",
      },
      "& > span": {
        fontSize: "var(--p)",
        color: "var(--grey-500)",
      },
    },
  };
}
