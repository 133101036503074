import Users from "./components/modules/users";
import Article from "./components/modules/article";
import Articles from "./components/modules/articles";
import ArticlesZen from "./components/modules/articles-zen";
import Quotes from "./components/modules/quotes";
import Feelings from "./components/modules/feelings";
import Tip from "./components/modules/tip";
import Tips from "./components/modules/tips";
import ArticleCategories from "./components/modules/article-categories";
import Glossary from "./components/modules/glossary";
import Annual from "./components/modules/annual";
import Organisation from "./components/modules/organisation";
import Plausible from "./components/modules/plausible";
import AppUsers from "./components/modules/app-users";
import * as Icons from "./components/icons";

function buildNavigation(feelingTypes) {
  return [
    {
      title: "Citations",
      slug: "citations",
      Icon: Icons.Message,
      Module: Quotes,
    },
    {
      title: "Articles",
      slug: "articles",
      Icon: Icons.Bookmark,
      menus: [
        {
          title: "Articles Conseils",
          slug: "articles-conseils",
          Icon: Icons.Bookmark,
          Module: Articles,
        },
        {
          title: "Articles Zen",
          slug: "articles-zen",
          Icon: Icons.Bookmark,
          Module: ArticlesZen,
        },
        {
          title: "Catégories",
          slug: "categories",
          Icon: Icons.Bookmark,
          Module: ArticleCategories,
          superadmin: true,
        },
      ],
    },
    {
      title: "Article Éditorial",
      slug: "article/:id",
      hidden: true,
      Module: Article,
      options: { type: 1 },
    },
    {
      title: "Article Zen",
      slug: "article-zen/:id",
      hidden: true,
      Module: Article,
      options: { type: 2 },
    },
    {
      title: "Glossaire",
      slug: "glossaire",
      Icon: Icons.Paper,
      Module: Glossary,
    },
    {
      title: "Organisation",
      slug: "annuaire/:id",
      hidden: true,
      Module: Organisation,
    },
    { title: "Annuaire", slug: "annuaire", Icon: Icons.Book, Module: Annual },
    {
      title: "Utilisateurs",
      slug: "utilisateurs",
      Icon: Icons.User,
      Module: AppUsers,
    },
    {
      title: "Astuces",
      slug: "astuces",
      Icon: Icons.Lightbulb,
      Module: Tips,
      superadmin: true,
    },
    {
      title: "Astuce",
      slug: "astuce/:id",
      hidden: true,
      Module: Tip,
      superadmin: true,
    },
    {
      title: "Options",
      slug: "options",
      Icon: Icons.Gear,
      menus:
        feelingTypes &&
        feelingTypes.map((feelingType) => ({
          title: feelingType.label,
          slug: feelingType.path,
          Module: Feelings,
          Icon: Icons.Gear,
          superadmin: true,
          options: feelingType,
        })),
    },
    {
      title: "Statistiques",
      slug: "statistiques",
      Icon: Icons.Statistics,
      menus: [
        {
          title: "Globales",
          slug: "statistiques/globales",
          Module: Plausible,
        },
        {
          title: "Conseils",
          slug: "statistiques/articles",
          Module: Plausible,
          options: {
            filter: "page=~Conseils",
          },
        },
        {
          title: "Zen",
          slug: "statistiques/articles-zen",
          Module: Plausible,
          options: {
            filter: "page=~Zen",
          },
        },
      ],
    },
    {
      title: "Utilisateurs back-office",
      slug: "utilisateurs-backoffice",
      Icon: Icons.User,
      Module: Users,
      superadmin: true,
    },
  ];
}

export function getNavigation(isSuperAdmin, feelingTypes) {
  const navigation = buildNavigation(feelingTypes);
  if (isSuperAdmin) return navigation;
  return navigation.reduce((acc, entry) => {
    if (entry.superadmin) return acc;
    const menus = entry.menus?.filter((entry) => !entry.superadmin);
    if (!menus) return [...acc, entry];
    return [...acc, { ...entry, menus }];
  }, []);
}

export function getFlatenedNavigation(isSuperAdmin, feelingTypes) {
  const navigation = getNavigation(isSuperAdmin, feelingTypes);
  return navigation.reduce(
    (acc, item) => (item.menus ? [...acc, ...item.menus] : [...acc, item]),
    []
  );
}
