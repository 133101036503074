import * as React from "react";

// https://usehooks.com/useLocalStorage/
export default function useLocalStorage(key, initialValue, session = false) {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = session
        ? window.sessionStorage.getItem(key)
        : window.localStorage.getItem(key);
      return item !== null ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.info(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      if (valueToStore !== undefined) {
        const _value = JSON.stringify(valueToStore);
        if (session) {
          window.sessionStorage.setItem(key, _value);
        } else {
          window.localStorage.setItem(key, _value);
        }
      } else {
        if (session) {
          window.sessionStorage.removeItem(key);
        } else {
          window.localStorage.removeItem(key);
        }
      }
      setStoredValue(valueToStore);
    } catch (error) {
      console.info(error);
    }
  };

  return [storedValue, setValue];
}
