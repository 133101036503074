import { Box } from "@mui/system";
import * as React from "react";

export default function Loader() {
  const classes = styles();
  return <Box sx={classes.loader} />;
}

function styles() {
  return {
    loader: {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      userSelect: "none",
      cursor: "progress",
      zIndex: 200000,
    },
  };
}
