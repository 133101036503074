import * as React from "react";
import Management from "./lists/article";
import * as API from "../../services/api";

const messages = {
  title: "Gestion des astuces",
  addButton: "Ajouter une astuce",
  deleteModal: "Supprimer une astuce",
  deleteSuccess: "Astuce supprimée",
};

export default function Tips({ Icon }) {
  return (
    <Management
      Icon={Icon}
      get={get}
      remove={API.removeTip}
      route="/astuce"
      messages={messages}
    />
  );
}

function get(token) {
  return API.getTips(token);
}
