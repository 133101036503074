import * as React from "react";
import { Box, Checkbox, IconButton, TextField } from "@mui/material";
import Actions from "../../list-actions";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const initialEntry = { label: "", enabled: false };

export default function ListActions({
  handleChange,
  toggleEnabled,
  add,
  entries,
  setAdd,
  addEntry,
  messages,
}) {
  const classes = styles();

  const createEntry = async () =>
    add
      ? await addEntry(add)
      : setAdd({ ...initialEntry, position: entries.length });

  const renderTools = () => (
    <>
      <TextField
        sx={classes.textfield}
        value={add.label}
        onChange={handleChange(add)}
        autoFocus
        variant="standard"
      />
      <Box sx={classes.enable}>
        <Checkbox
          checked={add.enabled}
          color="primary"
          onChange={toggleEnabled(add)}
        />
      </Box>
      <Box sx={classes.tools}>
        <IconButton color="primary" onClick={() => addEntry(add)}>
          <DoneIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => setAdd(undefined)}>
          <CloseIcon />
        </IconButton>
      </Box>
    </>
  );

  return (
    <Actions message={messages.addButton} action={createEntry}>
      {add && renderTools()}
    </Actions>
  );
}

function styles() {
  return {
    textfield: {
      padding: "0 16px",
      flex: 1,
    },
    enable: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "0 0 84px",
    },
    tools: {
      flex: "0 0 100px",
    },
  };
}
