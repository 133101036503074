import * as React from "react";
import { TextField } from "@mui/material";
import Modal from "../../modal";
import { useAdminContext } from "../../../hooks/use-admin-context";

function EditModal({ add, callback, title }, ref) {
  const [author, setAuthor] = React.useState(add.author);
  const [content, setContent] = React.useState(add.content);
  const { closeModal } = useAdminContext();
  const classes = styles();

  const cancel = () => closeModal();

  const confirm = () => {
    callback({ ...add, author, content });
    closeModal();
  };

  const handleAuthorChange = (e) => setAuthor(e.target.value);
  const handleContentChange = (e) => setContent(e.target.value);

  const actions = [
    { title: "Annuler", color: "secondary", variant: "text", onClick: cancel },
    { title: "Confirmer", color: "primary", onClick: confirm },
  ];

  return (
    <Modal title={title} actions={actions} ref={ref}>
      <TextField
        sx={classes.textfield}
        label="Auteur"
        value={author}
        onChange={handleAuthorChange}
        variant="standard"
      />
      <TextField
        sx={classes.textfield}
        label="Citation"
        value={content}
        rows={5}
        onChange={handleContentChange}
        variant="standard"
        multiline
      />
    </Modal>
  );
}

function styles() {
  return {
    textfield: {
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
    },
  };
}

export default React.forwardRef(EditModal);
