import * as React from "react";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Button from "./ui/button";

function Modal({ children, title, actions }, ref) {
  const classes = styles();

  return (
    <Card ref={ref}>
      <CardHeader sx={classes.modalHeader} title={title} />
      <CardContent sx={classes.modalInner}>{children}</CardContent>
      <CardActions sx={classes.modalButtons}>
        {actions.map(({ title, variant = "contained", ...props }, index) => (
          <Button // color, variant, disabled, onClick
            key={`card-action-${index}`}
            sx={classes.modalButton}
            variant={variant}
            type="submit"
            {...props}
          >
            {title}
          </Button>
        ))}
      </CardActions>
    </Card>
  );
}

export default React.forwardRef(Modal);

function styles() {
  return {
    modalHeader: {
      padding: "25px 25px 0 25px",
    },
    modalInner: {
      display: "flex",
      flexDirection: "column",
      padding: "25px",
    },
    modalButtons: {
      padding: "0 25px 25px 25px",
    },
    modalButton: {
      margin: "0",
      "&:last-child": {
        marginLeft: "auto",
      },
    },
  };
}
