import * as React from "react";
import * as API from "../services/api";
import { BrowserRouter as Router } from "react-router-dom";
import { AppBar, CircularProgress, Toolbar } from "@mui/material";
import { useTheme } from "@mui/styles";
import Button from "./ui/button";
import Menu from "./menu";
import Content from "./content";
import { useAuth } from "../hooks/use-auth";
import { Box } from "@mui/system";
import * as Icons from "./icons";
import { formatUsername } from "../services/utils";

export default function Admin() {
  const theme = useTheme();
  const classes = styles(theme);

  const { user, token, logout } = useAuth();
  const [feelingTypes, setFeelingTypes] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const formatedUsername = formatUsername(user.email);

  const _fetchFeelingTypes = React.useCallback(async () => {
    const feelingTypes = await API.getFeelingTypes(token);
    setFeelingTypes(feelingTypes);
    setLoading(false);
  }, [token]);

  React.useEffect(() => {
    _fetchFeelingTypes();
  }, [_fetchFeelingTypes]);

  return (
    <Box sx={classes.root}>
      <AppBar sx={classes.appBar} position="static">
        <Toolbar sx={classes.toolbar}>
          <Box component="span" sx={classes.toolbarName}>
            Mon Carnet de Route Admin
          </Box>
          <Box component="span" sx={classes.toolbarUsername}>
            <Icons.User />
            {formatedUsername}
          </Box>
          <Button
            sx={classes.toolbarButton}
            color="secondary"
            variant="contained"
            onClick={logout}
          >
            Déconnexion
          </Button>
        </Toolbar>
      </AppBar>
      <Router>
        <Box sx={classes.container}>
          <Box component="nav" sx={classes.drawer} aria-label="categories">
            {loading ? (
              <CircularProgress />
            ) : (
              <Menu feelingTypes={feelingTypes} />
            )}
          </Box>
          <Box sx={classes.content}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Content feelingTypes={feelingTypes} />
            )}
          </Box>
        </Box>
      </Router>
    </Box>
  );
}

function styles(theme) {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      height: "100vh",
    },
    appBar: { boxShadow: "none" },
    toolbar: {
      color: theme.palette.theming.white,
    },
    toolbarName: {
      fontFamily: "Trocchi",
      fontSize: "22px",
    },
    toolbarUsername: {
      display: "flex",
      alignItems: "center",
      margin: "0 20px 0 auto",
      "&>svg": {
        height: 18,
        width: 18,
        marginRight: "10px",
        fill: "currentColor",
      },
    },
    toolbarButton: {},
    container: {
      display: "flex",
      flex: "1 1 100%",
      backgroundColor: "#fff",
    },
    content: {
      flex: "1 1 100%",
      height: "100%",
      position: "relative",
      backgroundColor: "#fafafa",
      "& > *": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#fafafa",
        ...theme.scrollbar,
      },
    },
    drawer: {
      height: "100%",
      padding: "0",
      "& > ul": {
        padding: "10px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 300px",
        maxHeight: "calc(100vh - 64px)",
        ...theme.scrollbar,
      },
    },
  };
}
