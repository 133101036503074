import * as React from "react";
import Actions from "../../list-actions";
import EditModal from "../glossary/edit-modal";
import { useAdminContext } from "../../../hooks/use-admin-context";

const initialDefinition = {
  word: "",
  definition: "",
  kind: "",
  enabled: false,
};

export default function ListActions({ definitions, callback, addDefinition }) {
  const { openModal } = useAdminContext();
  const createDefinition = async (newDefinition) => {
    newDefinition
      ? await addDefinition(newDefinition)
      : callback({ ...initialDefinition, position: definitions.length });
  };

  const onAddClick = () =>
    openModal(
      <EditModal
        add={initialDefinition}
        callback={createDefinition}
        title="Ajouter une définition"
      />
    );

  return <Actions message="Ajouter une définition" action={onAddClick} />;
}
