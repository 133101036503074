import * as React from "react";
import { useNavigate } from "react-router-dom";
import Actions from "../../list-actions";

export default function ListActions({ messages, route }) {
  const navigate = useNavigate();

  const createItem = () => navigate(`${route}/creation`);

  return <Actions message={messages.addButton} action={createItem} />;
}
