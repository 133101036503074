import * as React from "react";

export default function Lightbulb() {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.667 9.667a7.5 7.5 0 1 1 10.625 6.82v2.347H9.458v-2.171a7.5 7.5 0 0 1-4.792-6.996Zm3.125 8.057a9.167 9.167 0 1 1 9.167-.241V20.5H7.791v-2.776ZM9.167 21.5a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Z"
      />
    </svg>
  );
}
