import * as React from "react";
import Actions from "../../list-actions";
import EditModal from "../quotes/edit-modal";
import { useAdminContext } from "../../../hooks/use-admin-context";

const initialQuote = { author: "", content: "", enabled: false };

export default function ListActions({ quotes, callback, addQuote }) {
  const { openModal } = useAdminContext();
  const createQuote = async (newQuote) => {
    newQuote
      ? await addQuote(newQuote)
      : callback({ ...initialQuote, position: quotes.length });
  };

  const onAddClick = () =>
    openModal(
      <EditModal
        add={initialQuote}
        callback={createQuote}
        title="Ajouter une citation"
      />
    );

  return <Actions message="Ajouter une citation" action={onAddClick} />;
}
