import * as React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";

function CustomButton({ variant, sx, ...props }, ref) {
  const theme = useTheme();
  const classes = styles(theme);
  const buttonStyles =
    variant === "contained"
      ? { ...sx, ...classes.contained, ...classes.base }
      : { ...sx, ...classes.base };
  return (
    <Button
      ref={ref}
      variant={variant}
      sx={buttonStyles}
      disableElevation
      {...props}
    />
  );
}

export default React.forwardRef(CustomButton);

function styles(theme) {
  return {
    base: {
      borderRadius: theme.radiuses.small,
    },
    contained: {
      color: theme.palette.theming.white,
    },
  };
}
