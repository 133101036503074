import * as React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../modal";
import { useAdminContext } from "../../../hooks/use-admin-context";

function CancelModal({ page }, ref) {
  const { closeModal } = useAdminContext();
  const navigate = useNavigate();

  const cancel = () => closeModal();
  const confirm = () => {
    navigate(page);
    closeModal();
  };

  const actions = [
    { title: "Annuler", color: "secondary", variant: "text", onClick: cancel },
    { title: "Confirmer", color: "primary", onClick: confirm },
  ];

  return (
    <Modal title="Annuler les modifications" actions={actions} ref={ref}>
      Vos modifications ne seront pas sauvegardée.
    </Modal>
  );
}

export default React.forwardRef(CancelModal);
