import * as React from "react";
import { useTheme } from "@mui/styles";
import * as Utils from "../services/utils";
import { Box } from "@mui/system";

function ColorPicker({ setColor, selected }) {
  const theme = useTheme();
  const classes = styles(theme);

  React.useEffect(() => {
    if (!selected) setColor(theme.palette.components.blue.light);
  }, [selected, setColor, theme.palette.components.blue.light]);

  const renderColors = () =>
    Object.keys(theme.palette.components)
      .reduce((acc, color) => {
        const value = theme.palette.components[color];
        return Utils.isObject(value)
          ? [
              ...acc,
              ...Object.keys(value).map((nestedColor) => value[nestedColor]),
            ]
          : [...acc, value];
      }, [])
      .map((color) => (
        <Color
          key={`color-${color}`}
          value={color}
          setColor={setColor}
          selected={color === selected?.toLowerCase()}
        />
      ));

  return <Box sx={classes.picker}>{renderColors()}</Box>;
}

export default React.memo(ColorPicker);

function styles(theme) {
  return {
    picker: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    color: {
      borderRadius: "50%",
      height: "35px",
      width: "35px",
      border: "3px solid",
      borderColor: theme.palette.theming.white,
      transition: "border-color .22s ease-in-out",
      cursor: "pointer",
      "&:hover, &:focus": {
        borderColor: "rgba(255, 255, 255, .5)",
      },
    },
    colorSelected: {
      borderColor: "rgba(255, 255, 255, .75)",
    },
  };
}

function Color({ setColor, value, selected }) {
  const theme = useTheme();
  const classes = styles(theme);
  const classNames = selected
    ? { ...classes.color, ...classes.colorSelected }
    : classes.color;
  return (
    <Box
      sx={classNames}
      component="button"
      style={{ backgroundColor: value }}
      onClick={() => setColor(value)}
    />
  );
}
