import * as React from "react";
import { CircularProgress } from "@mui/material";
import Login from "./components/login";
import Admin from "./components/admin";
import { useAuth } from "./hooks/use-auth";

export default function App() {
  const { user } = useAuth();
  const Display = user ? Admin : Login;
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <Display />
    </React.Suspense>
  );
}
